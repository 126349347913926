import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./NotificationConstant";
import { doGET } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows));
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
    return response?.data
  };

    onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };
 
 
}

const NotificationService = new Service();
export default NotificationService;
