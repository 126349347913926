export const ENDPOINTS = {
  grid: (page, rowPerPage, filter) => {
    let url = `/api/profile/admin/reviews?rowPerPage=` + rowPerPage + "&page=" + page

    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    return url
  },
  gridrequested: (page, rowPerPage, filter) => {
    let url = `/api/profile/admin/reviews/requested?rowPerPage=` + rowPerPage + "&page=" + page

    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    return url
  },
  gridrejected: (page, rowPerPage, filter) => {
    let url = `/api/profile/admin/reviews/rejected?rowPerPage=` + rowPerPage + "&page=" + page


    if (filter?.fromDate) {
      url += `&fromDate=${filter?.fromDate}`
    }
    if (filter?.tillDate) {
      url += `&tillDate=${filter?.tillDate}`
    }
    return url
  },
  get: (id) => `/api/profile/admin/get/review/${id}`,
  approve: (id) => `/api/profile/admin/approve/review/${id}`
};