import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import moment from "moment/moment";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import { Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import FinanceService from "./FinanceService";
import "./style.css";
import image from "../../assets/images/imagplaceholder.png";
import DateSelect from "../../components/DateSelects/DateSelect";
import ImageComponent from "../../components/Image/ImageComponent";

import RecordComponent from "./RecordComponent";
const Finance = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [showViewPage, setShowViewPage] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
  const [formattedFromDate, setFormattedFromDate] = useState();
  const [toDate, setToDate] = useState(moment().format('YYYYMMDD'))
  const [formattedToDate, setFormattedToDate] = useState();
  const [data, setData] = useState({});
  const [forDownload, setForDownload] = useState(false);
  const [userType, setUserType] = useState({
    label: "Customer",
    value: 2,
  });
  const [searchStatus, setSearchStatus] = useState({
    label: "All",
    value: 0,
  });

  const formatDate = (inputDate = 0) => {
    const dateString = inputDate.toString();
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const fetchData = async ({ isDownload = false, fromDate, toDate }) => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      if (searchName) {
        filter.name = searchName;
      }
      if (fromDate || toDate) {
        filter.fromDate = fromDate;
        filter.tillDate = toDate;
      }
      if (isDownload) {
        filter.forDownload = true;
      }
      if (userType.value) {
        filter.userType = userType?.value
      }
      if (searchStatus.value) {
        filter.searchStatus = searchStatus?.value
      }
      const response = await FinanceService.fetch(filter);
      console.log(response);
      if (isDownload) {
        const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
        const link = document.createElement('a');
        link.href = csvContent;
        link.target = '_blank';
        link.download = 'revenue.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setData(response.page)
    } catch (e) {
      console.log(e, "================================================");

      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData({ isDownload: false, fromDate, toDate });
  }, [FinanceService?.page, FinanceService?.rows, userType, searchStatus]);
  useEffect(() => {
    setFormattedFromDate(formatDate(fromDate));
    setFormattedToDate(formatDate(toDate));

  }, [fromDate, toDate]);

  let navigate = useNavigate();

  const userTypeOptions = [
    {
      icon: "",
      label: "Consultant",
      value: 0,
      callback: (e) => {
        changePagination();
        setUserType({
          ...userType,
          label: "Consultant",
          value: 1,
        })
      },
    },
    {
      icon: "",
      label: "Customer",
      value: 1,
      callback: (e) => {
        changePagination();
        setUserType({
          ...userType,
          label: "Customer",
          value: 2
        })
      },
    },
    {
      icon: "",
      label: "Wishtok",
      value: 2,
      callback: (e) => {
        changePagination();
        setUserType({
          ...userType,
          label: "Wishtok",
          value: 3
        })
      },
    },
  ]

  const changePagination = () => {
    if (FinanceService.page != 1) {
      FinanceService.page = 1;
    }
  }

  const StatusOptions = [
    {
      label: "All",
      value: 0,
      callback: () => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "All",
          value: 0,
        })
      },
    },
    {
      label: "Initiated",
      value: 1,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Initiated",
          value: 1
        })
      },
    },
    {
      label: "Successful",
      value: 2,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Successful",
          value: 2
        })
      },
    },
    {
      label: "Failed",
      value: 2,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Failed",
          value: 3
        })
      },
    },
    {
      label: "Manual",
      value: 2,
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Manual",
          value: 10
        })
      },
    },
  ]


  return (
    <>
      <Layout
        changePagination={changePagination}
        filterUserType
        filterStatus
        userType={userType?.label}
        userTypeOptions={userTypeOptions}
        hideTitleBar={true}
        download={true}
        showFromToDateFilter
        fromToPlaceholder={["From", "To"]}
        filterInput={true}
        hideNameInput={true}
        search1={searchName}
        toDate={toDate}
        fromDate={fromDate}
        forDownload={forDownload}
        setForDownload={setForDownload}
        setToDate={setToDate}
        setFromDate={setFromDate}
        setSearch1={setSearchName}
        fetchData={(args) => {
          fetchData({ ...args });
        }}
        showJoinDate
        onAddClick={() => {
          navigate("/finance/edit");
        }}
        gridLoading={loading}
        rows={FinanceService.rows}
        records={FinanceService.records}
        total={FinanceService.totalRecords}
        page={FinanceService.page}
        showViewPage={showViewPage}
        // title={t("Revenue")}
        onPageChange={async (page, rows) => {
          await FinanceService.onPaginationChange(page, rows);
        }}
        onDateChange={(title, fromDate, tillDate) => {
          fetchData({ isDownload: false, fromDate, toDate: tillDate })
        }}
        statusAction={StatusOptions}
        searchStatus={searchStatus.label}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Payment Time")}</Th>
              <Th>{t("Payment")}</Th>
              <Th>{t("Offer Amount")}</Th>
              <Th className="text-center">{t("User Type")}</Th>
              <Th className="text-center">{t("Transaction Id")}</Th>
              <Th>{t("Message")}</Th>
              <Th>{t("Offer Code")}</Th>
              <Th className="text-center">{t("Status")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {FinanceService?.records?.map((record) => (
              <RecordComponent record={record} />
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout >
    </>
  );
};

export default observer(Finance);
