import React, { useState, useEffect, useContext, useRef } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Row, UncontrolledAccordion } from 'reactstrap'
import SendIcon from '../../assets/icons/send'
import SupportService from './SupportService'
import { useLocation, useParams } from "react-router-dom";
import ConsultantService from '../Consultant/ConsultantService'
import ClientService from '../Clients/ClientService'
import { DialogContext } from '../../store/context/DialogContext';
import { UserContext } from '../../store/context/userContext';
import { dateFormat2 } from '../../util/helper';
import ImageComponent from '../../components/Image/ImageComponent';
import ImageModal from '../../components/Image/ImageModal';
import FileUploadImg from '../../assets/images/File.svg'
import './style.css'
import { ThreeBounce } from 'better-react-spinkit';
import { checkImageType } from '../../util/helper';

const SupportDetail = ({ }) => {
    const chatContainerRef = useRef(null);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { ticketNo, user_id, userType } = location.state || "";
    const [userInfo, setUserInfo] = useState({})
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const { user } = useContext(UserContext)
    const [newData, setNewData] = useState({

        ticketNo: ticketNo
    })

    const [chatData, setChatData] = useState(null)
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [currentChatData, setCurrentChatData] = useState({});
    const [currentTicketInfo, setCurrentTicketInfo] = useState({})
    const [supportUserInfo, setSupportUserInfo] = useState({})

    const { id, ticket_id } = useParams()
    const inputRefText = useRef(null);
    const inputRefFile = useRef(null);

    const sendMessage = async () => {
        setLoading(true);
        try {
            const currentChatDataFormData = new FormData()

            if (currentChatData?.desc) {
                currentChatDataFormData.append('desc', currentChatData?.desc)
            }

            if (currentChatData?.attachment) {
                if (!checkImageType(currentChatData?.attachment)) {
                    showMessage("info", "Please upload jpg, jpeg, png file only")
                    return;
                }
                currentChatDataFormData.append('supportDoc', currentChatData?.attachment)
            }

            currentChatDataFormData.append('ticket_id', ticket_id);
            if (chatData?.length)
                currentChatDataFormData.append('prev_id', chatData[chatData?.length - 1]?._id);


            const response = await SupportService?.addTicketChatInfo(currentChatDataFormData);

            if (response) {
                setChatData(response?.data?.rows)
            }
        } catch (error) {
            showError(error);
        }
        if (inputRefText.current) {
            inputRefText.current.value = ""
        }
        // inputRefText.current.value = "";
        if (inputRefFile.current) {
            inputRefFile.current.value = null;
        }
        setIsImageModalOpen(false)
        setLoading(false);
        setCurrentChatData(null)
    }

    const getChats = async () => {
        setLoading(true)
        try {

            let query = ''
            query += `?ticketNo=${id}`
            const response = await SupportService?.getTicketChatInfo(query);
            if (response?.data?.rows?.length) {
                setChatData(response?.data?.rows)
            }

        } catch (error) {
            showError(error);
        }
        setLoading(false)
    }

    const getTicketInfo = async () => {
        setLoading(true);
        try {
            const response = await SupportService?.getTicketInfo(ticket_id);

            if (response) {
                setCurrentTicketInfo(response?.data)
            }

        } catch (error) {
            showError(error);
        }
        setLoading(false)
    }

    const getSupportUserInfo = async () => {
        setLoading(true);
        try {
            const response = await SupportService?.getSupportUserInfo(ticket_id);
            if (response) {
                setSupportUserInfo(response?.data)
            }
        } catch (error) {
            showError(error);
        }
        setLoading(false)
    }

    const handleUpdateTicket = async () => {
        try {
            const response = await SupportService?.updateTicket(ticket_id, currentTicketInfo);
            if (response?.data?.data?.status === 2) {
                setCurrentTicketInfo({ ...currentTicketInfo, status: 2 });
                showMessage('success', 'Ticket marked as resolved');
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatData]);

    useEffect(() => {
        getChats()
        getTicketInfo()
        getSupportUserInfo()
    }, [])


    return (
        <div>
            <Card className="mt-3 mb-5 mx-2 " style={{
                // height: "100vh",
                // overflow: "auto",
                paddingBottom: "150px"
            }}>
                <div className='d-flex justify-content-between '>
                    <div className='hide-chat-scrollbar' style={{
                        width: "65%",
                    }}>
                        <Row>
                            <div className='d-flex justi
                            fy-content-between p-4'>
                                <div className='d-flex'>
                                    <ImageComponent source={currentTicketInfo?.userPic} style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "40px",
                                        objectFit: 'cover',
                                        marginRight: "10px"
                                    }} />
                                    <div className='d-flex justify-content-center align-items-center' style={{
                                        color: "#3E4259",
                                        fontSize: "20px"
                                    }}>
                                        {`${supportUserInfo?.fname ? supportUserInfo?.fname : ''} ${supportUserInfo?.lname ? supportUserInfo?.lname : ''}`}
                                        <div style={{
                                            color: "rgb(125 125 125)",
                                            fontSize: "20px",
                                            marginLeft: "30px",
                                        }}>
                                            {`#${currentTicketInfo?.ticketNo ? currentTicketInfo?.ticketNo : ""}`}
                                        </div>
                                    </div>
                                </div>

                                <div className='ms-5 align-self-center' style={{
                                    color: "#666666",
                                    fontSize: "20px"
                                }}>
                                    {currentTicketInfo?.status === 2 ? (
                                        <button type="button" className="btn btn-success">Resolved</button>
                                    ) : (
                                        <button onClick={handleUpdateTicket} type="button" className="btn btn-danger">Mark Resolved</button>
                                    )}
                                </div>
                            </div>
                            <hr className='text-dark mx-5' />
                            <div id="chat"
                                className='hide-chat-scrollbar'
                                ref={chatContainerRef}
                                style={{
                                    height: "70vh",
                                    overflow: "auto",
                                }}>
                                {chatData?.map((ele) =>
                                (
                                    <Row>
                                        <Row style={{ display: "flex", marginLeft: "12px", justifyContent: (ele?.userType == 1 || ele?.userType == 2) ? "flex-start" : "flex-end" }}>
                                            <Card className='d-flex flex-1' style={{
                                                minWidth: '20%', maxWidth: "70%", marginLeft: "20px", backgroundColor: (ele?.userType == 1 || ele?.userType == 2) ? "#CECDF84D" : "#F5F5F5",
                                                borderRadius: "14.163px",
                                                borderBottomRightRadius: (ele?.userType === 1 || ele?.userType === 2) ? '0px' : '14.163px',
                                                borderBottomLeftRadius: (ele?.userType === 1 || ele?.userType === 2) ? '14.163px' : '0px'
                                            }}>
                                                <CardBody style={{
                                                    margin: "-10px"
                                                }}
                                                >
                                                    <div className={`${!(ele?.userType == 1 || ele?.userType == 2) ? "d-flex flex-wrap flex-1 justify-content-start" : ""}`} >
                                                        {ele?.message ? <p style={{ fontSize: "14px", fontWeight: 500 }}>{ele?.message}</p> : <div>
                                                            <ImageComponent width={50} height={50} source={ele?.attachment} />
                                                        </div>}
                                                    </div>

                                                    <div className='align-items-end mt-1 ' style={{ float: (ele?.userType == 1 || ele?.userType == 2) ? "right" : "left", }}>
                                                        {dateFormat2(ele?.at)}
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        </Row>
                                    </Row>

                                ))
                                }
                            </div>
                            <Row className='d-flex justify-content-center' >
                                <div className='row d-flex'>
                                    <div className='col-sm-11'>
                                        <div className='d-flex'>
                                            <input ref={inputRefText}
                                                style={{
                                                    outline: "none",
                                                    borderRadius: "30px",
                                                    border: "1px solid black",
                                                    height: "55px",
                                                    padding: "4px",
                                                    fontSize: "20px",
                                                    paddingLeft: "15px",
                                                    paddingRight: "80px"
                                                }}
                                                placeholder='Type a Message'
                                                value={currentChatData?.desc}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        sendMessage()
                                                    }

                                                }}
                                                onChange={(v) => {
                                                    setCurrentChatData({
                                                        ...currentChatData,
                                                        desc: v.target.value
                                                    })

                                                }} className="col-sm-12 mx-3" />

                                            <div className="" style={{
                                                position: 'relative',
                                                right: 70,
                                                top: 5
                                            }}>
                                                <input
                                                    type="file"
                                                    name="file-input"
                                                    id="file-input"
                                                    className="file-input__input bg-danger"
                                                    ref={inputRefFile}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            sendMessage()
                                                        }

                                                    }}
                                                    onChange={(v) => {
                                                        if (v.target?.files?.length > 0) {
                                                            setIsImageModalOpen(true);
                                                            setCurrentChatData({ ...currentChatData, attachment: v.target?.files[0] })
                                                        }
                                                    }}
                                                />
                                                <label className="file-input__label" for="file-input">
                                                    <img src={FileUploadImg} />
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div onClick={() => {
                                        sendMessage()
                                    }} className='col-sm-1 align-items-center'
                                        style={{
                                            width: "55px",
                                            height: "55px",
                                            border: "1px solid",
                                            borderRadius: "30px",
                                            cursor: "pointer", right: "-32px"
                                        }}>
                                        <SendIcon />
                                    </div>
                                    {/* <input name="file-input" type='file'  className="col-sm-10 file-input__input" />

                                    <label className='file-input__label' for="file-input">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M18 12C16.322 12 14.751 12.46 13.407 13.259C14.6038 14.6576 15.5326 16.2648 16.147 18H18V12ZM14.004 18C12.044 13.302 7.408 10 2 10V18H14.004ZM2 8C5.83 8 9.323 9.435 11.974 11.796C13.7639 10.621 15.8589 9.9966 18 10V2H19.008C19.556 2 20 2.445 20 2.993V19.007C19.9982 19.2696 19.8931 19.521 19.7075 19.7068C19.5219 19.8926 19.2706 19.9979 19.008 20H0.992C0.728813 19.9997 0.476497 19.895 0.290489 19.7088C0.104482 19.5226 -1.33455e-07 19.2702 0 19.007V2.993C0.00183004 2.73038 0.1069 2.47902 0.292513 2.29322C0.478126 2.10742 0.72938 2.00209 0.992 2H4V0H6V4H2V8ZM16 0V4H8V2H14V0H16ZM14.5 9C14.1022 9 13.7206 8.84196 13.4393 8.56066C13.158 8.27936 13 7.89782 13 7.5C13 7.10218 13.158 6.72064 13.4393 6.43934C13.7206 6.15804 14.1022 6 14.5 6C14.8978 6 15.2794 6.15804 15.5607 6.43934C15.842 6.72064 16 7.10218 16 7.5C16 7.89782 15.842 8.27936 15.5607 8.56066C15.2794 8.84196 14.8978 9 14.5 9Z" fill="black" />
                                        </svg>
                                    </label> */}

                                </div>

                            </Row>
                        </Row>
                    </div>
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.33)", width: "29%", borderRadius: "8px", height: "650px", marginTop: "88px", marginRight: "20px" }}>
                        <div className='d-flex justify-content-center mt-5'>
                            <ImageComponent source={currentTicketInfo?.userPic} style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "80px",
                                objectFit: 'cover'
                            }} />

                        </div>
                        <div className='d-flex justify-content-center mt-2'>
                            <strong>
                                {`${supportUserInfo?.fname ? supportUserInfo?.fname : ''} ${supportUserInfo?.lname ? supportUserInfo?.lname : ""}`}
                            </strong>
                        </div>
                        <div style={{ overflow: "auto", maxHeight: "650px", }}>
                            <UncontrolledAccordion className="mt-3" defaultOpen="1">
                                <AccordionItem>
                                    <AccordionHeader targetId="1" >
                                        <span className='text-dark'>
                                            Ticket Info
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <Row className='my-2'>
                                            <Col>
                                                Status:  <strong> {`${currentTicketInfo?.status == 1 ? 'Open' : 'Closed'}`}</strong>
                                            </Col>
                                        </Row>
                                        {/* <Row className='my-2'>
                                                <Col>
                                                    Updated:      <strong> Just Now</strong>
                                                </Col>
                                            </Row> */}
                                        <Row className='my-2'>
                                            <Col>
                                                Total replies:   <strong> {chatData?.filter(obj => obj.userType === 0).length}</strong>
                                            </Col>
                                        </Row>
                                        {/* <Row className='my-2'>
                                                <Col>
                                                    Avg response time:    <strong> 2 hour 12 mins</strong>
                                                </Col>
                                            </Row> */}
                                    </AccordionBody>
                                </AccordionItem>
                            </UncontrolledAccordion>
                            <UncontrolledAccordion className="mt-3" defaultOpen="1">
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        <span className='text-dark'>

                                            Customer Info
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <Row>
                                            <Col className='my-2'>
                                                Email:  <strong> {supportUserInfo?.email ? supportUserInfo?.email : ""}</strong>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='my-2'>
                                                Role:    <strong> {`${supportUserInfo?.userType == 1 ? 'Consultant' : 'End User'}`}</strong>
                                            </Col>
                                        </Row>

                                    </AccordionBody>
                                </AccordionItem>
                            </UncontrolledAccordion>
                        </div>

                        {/* <UncontrolledAccordion className="mt-3" defaultOpen="1">
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            Media
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            <Row>
                                                <Col>
                                                    Email:  <strong> debeelaal@example.com</strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    Role:    <strong> End User</strong>
                                                </Col>
                                            </Row>

                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion> */}
                        {/* <UncontrolledAccordion className="mt-3" defaultOpen="1">
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            Customer Notes
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">

                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion> */}
                    </div>
                </div >
            </Card >

            {isImageModalOpen && <ImageModal handleSubmit={() => {
                sendMessage()
            }} setClose={() => {
                setIsImageModalOpen(false)
            }} imageUrl={currentChatData?.attachment} />}
        </div >
    )
}

export default SupportDetail