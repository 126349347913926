import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row, Button } from "reactstrap";
import { CardThreeBounce, DateSelect, InputField, PhoneNum, SaveButton, SelectField, SelectFieldMultiple } from "../../components";
import SupervisorService from "./SupervisorService";
import { useLocation, useNavigate } from "react-router-dom";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext";
import ImageUploader from "./ImageUploader";
const EditSupervisor = () => {
  const [loading, setLoading] = useState(false);
  const { setRoles } = useContext(UserContext);

  const location = useLocation();
  const [data, setData] = useState({
    roles: []
  })
  const [imgurl, setImgurl] = useState({})
  const { id } = location.state || "";
  let navigate = useNavigate();
  const { showError, showAlert } = useContext(DialogContext);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (data?.salutation == null || !data?.dob) {
      showError("Please Enter All Fields");
      return;
    }
    if (id == null && data.password !== data?.confirmPassword) {
      showError("Password doesn't match");
      return;
    }
    try {
      const formdata = new FormData();
      formdata.append("name", data?.name);
      formdata.append("email", data?.email);
      formdata.append("password", data?.password);
      formdata.append("countryCode", data?.countryCode);
      formdata.append("phone", data?.phone);
      formdata.append("salutation", data?.salutation);
      // formdata.append("adminRole", data?.adminRole);
      formdata.append("dob", data?.dob);
      formdata.append("location", data?.location);
      formdata.append("zipCode", data?.zipCode);
      formdata.append("address", data?.address);
      formdata.append("profileImage", imgurl);
      formdata.append('roles', data?.roles);
      formdata.append('status', data?.status)

      let response;
      if (id != null) {
        response = await SupervisorService.update(formdata, id)
        if (response) {
          setRoles(response);
          localStorage.setItem("roles", JSON.stringify(response))

        }

      }
      else
        response = await SupervisorService.save(formdata);
      SupervisorService.records.push(data);

      showAlert({
        title: id
          ? " Admin has been Updated Successfully"
          : "New Admin has been Created Successfully",
        description:
          "The details have been sent to the respective admin's Email Address",
      });

      navigate(`/supervisor`);
    } catch (e) {
      console.log(e);
      showError(e);
      // fetchData();
    }
  };
  const fetch = async () => {
    let supervisorD = {
    };
    if (id) {
      supervisorD = await SupervisorService.get(id);
    }
    setData(supervisorD);
    setLoading(false);
  };

  useEffect(() => {
    console.log(data, "----")

  }, [data])
  useEffect(() => {
    setLoading(true);
    fetch();
  }, [id]);
  const gender = [
    {
      label: "Male",
      value: 1,
    },
    {
      label: "Female",
      value: 2,
    },
    {
      label: "others",
      value: 3,
    }
  ]
  const department = [
    {
      label: "Customer Manager",
      value: "Customer",
    },
    {
      label: "Consultant Manager",
      value: "Consultant",
    },
    {
      label: "Finance Manager",
      value: "Finance",
    },
    {
      label: "Reviews Manager",
      value: "Reviews",
    },
    {
      label: "Support Admin",
      value: "Support",
    },
    {
      label: "Super Admin",
      value: "Admin",
    },
    {
      label: "Configuration Manager",
      value: "Configuration",
    }
  ]
  const salutation = [
    {
      value: 0,
      label: "Mr.",
    },
    {
      value: 1,
      label: "Mrs.",
    },
    {
      value: 2,
      label: "Miss.",
    }
  ]
  if (loading) return <CardThreeBounce />;
  return (
    <React.Fragment>

      <Card className="mt-3" style={{ width: "auto", height: "100%", overflow: "auto" }}>
        <CardBody className="py-3 px-5" style={{ top: "10%", position: "relative", width: "88%" }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
            <div>
              <ImageUploader imgurl={imgurl} setImgurl={setImgurl} src={data?.profileImage} />
              <div className="px-4" style={{ marginRight: "750px" }}>
                <h2 style={{ fontWeight: "bold" }}>
                  {data?.name}
                </h2>

              </div>
            </div>
            <div className="d-flex">
              <Button
                onClick={() => setData({ ...data, status: 0 })}
                style={{
                  color: data.status === 0 ? 'red' : 'black',
                  background: 'white',
                  border: data.status === 0 ? '0.1px solid red' : '0.1px solid black',
                  borderRadius: '20px',
                  marginRight: "4px"
                }}                                        >
                Inactive
              </Button>
              <Button
                onClick={() => setData({ ...data, status: 1 })}
                style={{
                  color: data.status === 1 ? 'green' : 'black',
                  background: 'white',
                  border: data.status === 1 ? '0.1px solid green' : '0.1px solid black',
                  borderRadius: '20px',
                }}
              >
                Active
              </Button>
            </div>
          </div>
          <Row className="mt-3">
            <Col className="col-sm-5" >
              <Label>
                Name <font color="#EC734B">*</font>
              </Label>
              <Row>
                <SelectField className="col-sm-4" data={salutation} placeholder="Salutation" value={data?.salutation} onChange={(v) => setData({ ...data, salutation: v.value })} />
                <InputField className="col-sm-8" placeholder="Enter Full Name" value={data?.name} onChange={(v) => setData({ ...data, name: v })} />
              </Row>
            </Col>
            <Col className="col-sm-5">
              <Label>
                Email
              </Label>
              <InputField placeholder="Enter Email Address" value={data?.email} onChange={(v) => setData({ ...data, email: v })} />
            </Col>
          </Row>
          <Row className="mt-3">

            <Col className="col-sm-5">
              <Label>
                Date of Birth <font color="#EC734B">*</font>
              </Label>
              <DateSelect placeholder="Select Date" value={data?.dob} onChange={(v) => setData({ ...data, dob: v })} />
            </Col>
            <Col className="col-sm-5">
              <Label>
                Role
              </Label>


              <SelectFieldMultiple
                data={department}
                placeholder="Select Role"
                value={department?.filter((c) =>
                  data?.roles?.includes(c?.value)
                )}

                multi={true}
                onChange={(v) => {
                  const rolesArray = v?.map(selectedRole => selectedRole.value);
                  setData({ ...data, roles: rolesArray });
                }}
              />

            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-5">
              <Label>
                Location
              </Label>
              <InputField placeholder="Enter location" value={data?.location} onChange={(v) => setData({ ...data, location: v })} />
            </Col>
            <Col className="col-sm-5">
              <Label>
                Mobile No.
              </Label>
              <PhoneNum
                value={{ countryCode: data?.countryCode, phone: data?.phone }}
                onChange={(v) => {
                  console.log(v);
                  setData({
                    ...data,
                    countryCode: v?.countryCode,
                    phone: v?.phone,
                  })
                }
                } />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-sm-5">
              <Label>
                Zip Code
              </Label>
              <InputField placeholder="Enter Zip Code" value={data?.zipCode} onChange={(v) => setData({ ...data, zipCode: v })} />
            </Col>
            <Col className="col-sm-5">
              <Label>
                Street Address
              </Label>
              <InputField placeholder="Enter Street address" value={data?.address} onChange={(v) => setData({ ...data, address: v })} />
            </Col>
          </Row>

          <Row className="mt-3">
            <SaveButton id={id} data={data} style={{ top: "5px" }} onClick={submitHandler} disabled={data ? false : true} />
          </Row>

        </CardBody>
      </Card>

    </React.Fragment>
  );
};

export default observer(EditSupervisor);
