export const ENDPOINTS = {
    grid: () => {
        let url = `/api/profile/get/support/grid`;
        return url;

    },

    gridTicket: (page, rowPerPage, filter) => {

        let url = `/api/support/ticket/grid?rowPerPage=` + rowPerPage + "&page=" + page;

        if (filter.status) {
            url += `&status=${filter?.status}`
        }
        if (filter.search) {
            url += `&search=${filter?.search}`
        }
        if (filter.userIds) {
            url += `&userIds=${filter?.userIds}`
        }
        if (filter.ticketNo) {
            url += `&ticketNo=${filter?.ticketNo}`
        }
        return url
    },

    gridActive: `/api/profile/get/support/active/grid`,
    gridResolved: `/api/profile/get/support/resolved/grid`,
    getSupportUser: (id) => `/api/support/ticket/${id}/get/user`,
    getTicketInfo: (ticket_id) => `/api/support/ticket/${ticket_id}/get`,
    gridConversation: (query = '') => `/api/support/ticket/conversation/grid${query}`,
    addConversation: `/api/support/ticket/conversation/add`,
    saveNew: `/api/profile/add/support/chat`,
    get: (id) => `/api/profile/support/chat/${id}`,
    staticFileUrl: (url) => `/api/auth/static/${url}`,
    updateTicket: (ticketId) => `/api/support/ticket/update/${ticketId}`,
    getCustomerGridByIds: "/v1/api/user/grid",
};