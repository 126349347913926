import React, { useContext, useEffect, useState } from "react";
import ConsultantService from "./ConsultantService";
import { DialogContext } from "../../store/context/DialogContext";
import { Row, Col, Label } from 'reactstrap'
import { InputField, SaveButton } from "../../components";
import { isObjectValidate } from "../../util/helper";
import { CrossButton } from "../../components";
import './style.css'

const Payout = (props) => {

    const [data, setData] = useState({});

    const { showError, showMessage } = useContext(DialogContext);
    const {
        setClose,
        payoutData,
        setIsPayoutArray,
        rowClicked
    } = props

    useEffect(() => {
        if (!isObjectValidate(payoutData)) {
            setData({
                transaction_id: payoutData?._id,
                consultant_id: payoutData?.user_id,
                amount: payoutData?.amount,
            })
        }
    }, [payoutData])

    const handleSubmit = async () => {
        if (!data?.msg) {
            showError('message should not be empty')
            return
        }
        try {
            const response = await ConsultantService.payout(data);
            if (response.data === 'Success') {
                showMessage('success', 'payout added Successfully')
                setIsPayoutArray((prevArray) => {
                    const newArray = [...prevArray];
                    newArray[rowClicked] = { data: true };
                    return newArray;
                })
            }
        } catch (error) {
            showError(error)
        }
        finally {
            setClose();
        }
    }

    return <div style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,

    }} className="d-flex justify-content-center align-items-center">
        <div style={{ backgroundColor: 'white', width: '600px', height: '300px', borderRadius: '10px', overflowY: 'scroll' }}>

            <div style={{ position: 'relative', right: 0 }}>
                <CrossButton onClick={setClose} />
            </div>

            <Row className="m-3 col-sm-6">
                <Label>Amount</Label>
                <Row>
                    <InputField
                        className="col"
                        required={true}
                        placeholder="Rate"
                        value={data?.amount}
                        onChange={(v) => setData({ ...data, amount: v })}
                        type="number"
                        isDisabled={true}

                    />
                </Row>
            </Row>

            <Row className="m-3">
                <Col className="col-sm-11">
                    <Label>Payout Details <font color="#EC734B">*</font></Label>
                    <Row style={{ marginLeft: '-1px' }}>
                        <textarea
                            rows="5"
                            className="custom_text_area"
                            required={true}
                            placeholder="Payout Details"
                            value={data?.msg}
                            onChange={(v) => {
                                setData({ ...data, msg: v.target.value })

                            }} />
                    </Row>
                </Col>

            </Row>

            <Row className="" style={{ marginTop: "50px" }}>
                <SaveButton
                    style={{ right: "0%" }}
                    onClick={handleSubmit}
                    label={"Save"}
                    disabled={data ? false : true}
                />
            </Row>
        </div>
    </div>


};

export default Payout;
