import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { InputField } from "./InputField/InputField";




const BankDetailComponent = ({ setData, ele, idx, setBankDetailsError }) => {

    const handleRemoveRow = (index) => {
        setData((prevState) => ({
            ...prevState,
            bankDetails: prevState.bankDetails.filter((_, i) => i !== index),
        }));

        setBankDetailsError((prev) => {
            const updatedArray = prev.includes(index) ? prev.filter((item) => item !== index) : prev;
            const finalArray = updatedArray.map((item) => (item > index ? item - 1 : item))
            return finalArray;
        });
    };

    useEffect(() => {
        if (!(ele?.accountNumber && ele?.ifsc && ele?.bankName && ele?.holderName)) {
            setBankDetailsError((prev) => {
                if (!prev.includes(idx)) {
                    return [...prev, idx];
                }
                return prev;
            });
        } else {
            setBankDetailsError((prev) => {
                return prev.filter((item) => item !== idx)
            });
        }
    }, [ele])

    return (
        <Row className="mt-3">
            <Col>
                <Label>Account Number</Label> <font color="#EC734B">*</font>
                <InputField
                    type="number"
                    placeholder="Enter Account Number"
                    value={ele?.accountNumber}
                    onChange={(v) =>
                        setData((prevState) => ({
                            ...prevState,
                            bankDetails: prevState.bankDetails.map((item, i) =>
                                i === idx ? { ...item, accountNumber: v } : item
                            ),
                        }))
                    }
                />
            </Col>
            <Col>
                <Label>IFSC Number</Label> <font color="#EC734B">*</font>
                <InputField
                    placeholder="Enter IFSC Number"
                    value={ele?.ifsc}
                    onChange={(v) =>
                        setData((prevState) => ({
                            ...prevState,
                            bankDetails: prevState.bankDetails.map((item, i) =>
                                i === idx ? { ...item, ifsc: v } : item
                            ),
                        }))
                    }
                />
            </Col>
            <Col>
                <Label>Bank Name</Label> <font color="#EC734B">*</font>
                <InputField
                    placeholder="Bank Name"
                    value={ele?.bankName}
                    onChange={(v) =>
                        setData((prevState) => ({
                            ...prevState,
                            bankDetails: prevState.bankDetails.map((item, i) =>
                                i === idx ? { ...item, bankName: v } : item
                            ),
                        }))
                    }
                />
            </Col>
            <Col>
                <Label>Holder Name</Label> <font color="#EC734B">*</font>
                <InputField
                    placeholder="Holder Name"
                    value={ele?.holderName}
                    onChange={(v) =>
                        setData((prevState) => ({
                            ...prevState,
                            bankDetails: prevState.bankDetails.map((item, i) =>
                                i === idx ? { ...item, holderName: v } : item
                            ),
                        }))

                    }
                />
            </Col>
            <Col style={{
                marginTop: "33px",
                // background: 'red',
                alignSelf: 'flex-start'
            }}>
                <i onClick={() => handleRemoveRow(idx)} style={{ cursor: 'pointer' }} className="fa fa-times p-1" aria-hidden="true"></i>
            </Col>
        </Row>
    )

};

export default BankDetailComponent;
