import React from 'react'
import { COLOR_MAP, getColor } from './DashboardConstant'
import {
    Col,
    Row,
} from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";

function CountCard({ data, title, loading }) {
    return (
        <>
            {Object?.keys(data)?.map((key, index) => {
                const label = data[key]?.label;
                const value = data[key]?.value;
                const onClickTab = data[key]?.onClick;
                if (Object?.keys(value)?.length > 1) {
                    const subfields = Object?.keys(value)?.map(subKey => (
                        <React.Fragment key={subKey}>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <ThreeBounce size={20} color="#9F73AB" />
                                </div>
                            ) : <>
                                <Col className={`col-6 text-start`}>{`${keyValPair[subKey]}:`}</Col>
                                <Col className='col-6 d-flex justify-content-between' style={{ color: "black", marginRight: "auto" }}>
                                    <Col className='col-12 me-0 flex-1'>{`${value[subKey]}`}</Col>
                                    {/* <Col className='col-6 ms-0 pe-0'>{title != "Orders" ? "AED" : ""}</Col> */}
                                </Col>
                            </>
                            }

                        </React.Fragment>
                    ));
                    return (
                        <div key={key} className='py-3 px-1 ms-2 mb-3 rounded-3 d-flex flex-column justify-content-between shadow' onClick={() => {
                            if (onClickTab) {
                                onClickTab()
                            }
                        }} style={styles()}>
                            <div className={`px-2 fw-bold truncate mb-3 ${typeof value === 'object' ? "text-start" : "text-center"}`} style={{ color: "black", fontSize: "15px", marginRight: "auto" }}>
                                {label}
                            </div>
                            <Row className='fw-bold text-center d-flex flex-1 align-items-center' style={{ fontSize: "12px", paddingLeft: "0px", marginLeft: "0px" }}>
                                {subfields}
                            </Row>
                        </div>
                    );
                } else {
                    const subfield = Object.keys(value).map(subKey => (
                        <React.Fragment key={subKey}>
                            <Col className="col-6' d-flex text-center justify-content-center" >{`${value[subKey]}`} {title != "Orders" ? "AED" : ""}</Col>
                        </React.Fragment>
                    ));
                    return (
                        <div key={index} className='p-3 ms-2 mb-3 rounded-3 d-flex flex-column justify-content-center shadow' onClick={() => {
                            if (onClickTab) {
                                onClickTab()
                            }
                        }} style={styles()}>
                            <div className='fw-bold truncate text-center' style={{ color: "black", fontSize: "12px" }}>{subfield} </div>
                            <div className='fw-bold text-center mt-2' style={{ fontSize: "15px", color: "black" }}>
                                {label}
                            </div>
                        </div>
                    );
                }
            })}
        </>

    );
}

export default CountCard;



const styles = (color) => ({
    backgroundColor: "white",
    width: 250,
    maxWidth: 500,
    maxHeight: 200,
    minHeight: 150,
    // color: color,
})

const keyValPair = {
    Missed: "Missed",
    Connected: "Connected",
    Duration: "Duration",
    totalAmount: "Total Amount (AED)",
    totalTransactions: "Total Transactions",
}