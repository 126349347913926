import PropTypes from "prop-types";
import React, { useContext } from "react";
import { connect } from "react-redux";
import "./components/SuperResponsiveTableStyle.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "toastr/build/toastr.min.css";
import HorizontalLayout from "./components/HorizontalLayout/";
import { authRoutes, userRoutes } from "./routes/allRoutes";
// Import scss
import "./assets/scss/module.scss";
import "./assets/scss/theme.scss";
import { changeTopbarTheme } from "./store/actions";
import { UserContext } from "./store/context/userContext";
import ThreeBounceLoader from "./components/Loader/ThreeBounceLoader.js";

const App = (props) => {
  const { isLoggedIn } = useContext(UserContext);

  function getLayout() {
    let layoutCls = HorizontalLayout;
    changeTopbarTheme("dark");
    return layoutCls;
  }
  const Layout = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map(({ path, Component }, key) => (
          <Route path={path} element={<Component />} key={key} />
        ))}
        {userRoutes.map(({ path, Component }, key) => (
          <Route
            path={path}
            element={
              isLoggedIn || localStorage.getItem("token") ?
                <Layout>
                  <Component />
                </Layout> : (
                  <Navigate to={{ pathname: "/login" }} />
                )
            }
            key={key}
          />
        ))}
      </Routes>
      <ThreeBounceLoader />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStateToProps, null)(App);

