import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, NavbarText, Row, Table } from "reactstrap";
import {
    DateSelect,
    InputField,
    SaveButton,
    SelectField,
    PhoneNum,
    SelectFieldMultiple,
} from "../../components";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import ThreeBounceLoader from "../../components/Loader/ThreeBounceLoader.js";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext.js";
import ClientService from "./ClientService";
import BankDetailComponent from "../../components/BankDetailComponent";
import Checkbox from "../Consultant/Checkbox";
import CountrySelect from "../../components/CountrySelect/CountrySelect";
import imageplaceholder from "../../assets/images/imagplaceholder.png";
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import ImageComponent from "../../components/Image/ImageComponent";
import "../Consultant/style.css";
import { isArray, isObject } from "lodash";

const salutation = [
    {
        value: 0,
        label: "Mr.",
    },
    {
        value: 1,
        label: "Mrs.",
    },
    {
        value: 2,
        label: "Miss.",
    },
];

const EditConsultant = (props) => {
    const [data, setData] = useState({
        salutation: 0,
        countryCode: 91,
        bankDetails: []
    });
    const [isDisabled, setIsDisabled] = useState();
    const navigate = useNavigate();
    let [profileImage, setProfileImage] = useState({
        file: null,
    })

    const { id } = useParams();
    const { showMessage, showError, showConfirm, showAlert } =
        useContext(DialogContext);
    const { setShowLoader } = useContext(UserContext);

    const getDetails = async () => {
        try {
            setShowLoader(true);
            let response = {};
            if (id) response = await ClientService.get(id);
            setData(response);
            setIsDisabled(response?.disable);
        } catch (error) { }
        setShowLoader(false);
    };



    useEffect(() => {

        if (id) {
            getDetails();
        }
    }, [id]);


    const updateDisable = async (isDisabled) => {
        try {
            setShowLoader(true);
            let response;
            if (id) {
                response = await ClientService.update({ disable: !isDisabled }, id);
            } else {
                response = await ClientService.save(data);
            }
            console.log(response);
            getDetails();
        } catch (err) {
            showError(err?.message);
        }
        setShowLoader(false);
    };
    const submitForm = async () => {
        try {


            let response;
            if (id) {

                response = await ClientService.edit(data, id);
            } else {

                response = await ClientService.save(data);
            }
            console.log(response);
            getDetails();
            showAlert({
                title: id
                    ? " User has been Updated Successfully"
                    : "New User has been Created Successfully",
                description:
                    "",
            });
            navigate(`/customer`);
            // showMessage("info", "Consultant saved successfully");
        } catch (err) {
            showError(err?.message);
        }
    };




    return (
        <React.Fragment>
            <Card
                className="mt-10 mb-10"
                style={{
                    // width: "auto",
                    // left: "70.23px",
                    // top: "55px",
                    height: "100%",
                    overflow: "auto",
                    // maxWidth: "1320px",
                }}
            >

                <CardBody
                    className="py-3 px-5"
                >

                    <div className="position-relative" style={{ display: "flex", alignItems: "center", }}>
                        <div>

                            <div
                                style={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                }}
                            >
                                {profileImage?.file ? <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={URL.createObjectURL(profileImage?.file)} /> :
                                    <ImageComponent source={data?.profileImage} style={{ width: "100%", height: "100%", objectFit: "cover" }} />}
                            </div>
                        </div>

                    </div>
                    <Row className="mt-3">
                        <Col className="col-sm-8">
                            <Label>Name</Label>
                            <Row>
                                <Col className="col-sm-2">
                                    <SelectField
                                        data={salutation}
                                        placeholder="Salutation"
                                        value={data?.salutation ? data?.salutation : 0}
                                        onChange={(v) => setData({ ...data, salutation: v.value })}
                                    />
                                </Col>
                                <InputField
                                    className="col-sm-4"
                                    required={true}
                                    placeholder="Enter First Name"
                                    value={data?.fname}
                                    onChange={(v) => setData({ ...data, fname: v })}
                                />
                                <InputField
                                    className="col-sm-6"
                                    required={true}
                                    placeholder="Enter Last Name"
                                    value={data?.lname}
                                    onChange={(v) => setData({ ...data, lname: v })}
                                />
                            </Row>
                        </Col>

                    </Row>
                    <Row className="mt-3">
                        <Col className="col-sm-4">
                            <Label>Email</Label> <font color="#EC734B">*</font>
                            <InputField
                                required={true}
                                isDisabled={true}
                                placeholder="Enter Email Address"
                                value={data?.email}
                                onChange={(v) => setData({ ...data, email: v })}
                            />
                        </Col>
                        <Col className="col-sm-4">
                            <Label>Date of Birth</Label>
                            <DateSelect
                                placeholder="Select Date"
                                value={data?.dob}
                                onChange={(v) => setData({ ...data, dob: v })}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-sm-4">
                            <Label>Phone No.</Label> <font color="#EC734B">*</font>
                            <PhoneNum
                                isDisabled={true}
                                value={{ countryCode: data?.countryCode ? data?.countryCode : 91, phone: data?.phone }}
                                onChange={(v) => {
                                    setData({
                                        ...data,
                                        countryCode: v?.countryCode,
                                        phone: v?.phone,
                                    });
                                }}
                                required={true}
                            />
                        </Col>
                        <Col className="col-sm-4">
                            <Label>Country</Label>
                            <CountrySelect
                                name={true}
                                placeholder="Enter country"
                                value={data?.country}
                                onChange={(v) => setData({ ...data, country: v })}
                            />
                        </Col>

                    </Row>
                    <Row className="mt-3 col-8 d-flex justify-content-end ">
                        <SaveButton
                            className="pe-0"
                            onClick={submitForm}
                            label={id ? "Update" : "Add"}
                            disabled={data ? false : true}
                        />
                    </Row>
                    <Row className="mt-5"></Row>
                </CardBody>

            </Card>
        </React.Fragment>
    );
};

export default observer(EditConsultant);
