import moment from "moment/moment";
export const isObjectValidate = (obj) => {
    return obj === null || obj === undefined || Object.keys(obj).length === 0;
}

export const dateFormat = (timeStamp, format = "'DD-MM-YYYY'") => {
    return moment.unix(timeStamp).format(format);
}

export const dateFormat2 = (timeStamp) => {
    return moment.unix(timeStamp).format('DD MMMM YYYY');
}

export const checkImageType = (imageUrl) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    const fileExtension = imageUrl.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension)
}


export const milliSecondsToSeconds = (timestamp) => {
    if (timestamp >= 1e12) {
        return moment(timestamp).unix();
    } else {
        return timestamp;
    }
}

export const getFormattedTime = (date) => moment(date, "YYYYMMDD").unix();