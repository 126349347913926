import React from 'react'
import { isObjectValidate } from '../../util/helper';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const Charts = ({ data, ...props }) => {
  // const monthNames = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ];

  // const generateChartData = () => {
  //   const last_month_id = data[11]?._id;
  //   const labels = data.map(item => monthNames[item._id - 12 + last_month_id]);
  //   return {
  //     labels,
  //     datasets: [
  //       {
  //         label: 'Dataset',
  //         data: data.map(item => item.totalAmount),
  //         borderColor: 'rgb(255, 99, 132)',
  //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //         yAxisID: 'y',
  //       },
  //     ],
  //   };
  // };
  // const chartData = generateChartData();
  return (
    !isObjectValidate(data) && <Line options={props?.options ? props?.options : options} data={data} className="p-4" height={"250px"} width={"900px"} />
  )
}

export default Charts


