import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "reactstrap";
import CountrySelectService from "./CountrySelectService";
import { SelectField } from "../SelectField/SelectField";

const CountrySelect = (props) => {
  const [COUNTRIES, setCountries] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        await CountrySelectService.fetch();
        setCountries(
          toJS(CountrySelectService.countries).map((c) => {
            return {
              ...c,
              value: props?.isd ? c?.isd : c?.name,
              label: props?.isd ? (
                <div>
                  {/* <img
                    src={c?.flag}
                    alt="flag"
                    style={{
                      display: "inline",
                      maxHeight: "15px",
                      margin: "5px",
                    }}
                  /> */}
                  {`+${c?.isd}`}
                </div>
              ) : props?.name ? (
                <div>
                  {`${c.name}`}
                </div>
              ) : (
                <div>
                  {` ${c.name}`}
                </div>
              ),
            };
          })
        );
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, []);

  const renderFunc = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        className={props?.className}
        {...innerProps}
      >
        <Table style={{ margin: "0px" }} className="dropdown-hover">
          <thead style={{ margin: "0px" }}>
            <tr style={{ margin: "0px" }}>
              <td className="col-3 py-3">
                <img
                  src={data.flag}
                  alt="flag"
                  style={{
                    display: "inline",
                    maxHeight: "15px",
                    margin: "0px 5px",
                  }}
                />
              </td>
              <td className="col-3 py-3">{`+${data.isd}`}</td>
              <td className="col-6 py-3">{`${data.name}`}</td>
            </tr>
          </thead>
        </Table>
      </div>
    );
  };

  return (
    <React.Fragment>
      {COUNTRIES.length > 0 && (
        <SelectField
          placeholder={props.placeholder}
          noLabel={props.noLabel}
          style={props?.style}
          plain={props.plain}
          className={props?.className}
          render={renderFunc}
          onChange={props?.onChange}
          containerStyle={props.containerStyle}
          data={COUNTRIES}
          value={props?.value}
          innerContainerStyle={props.innerContainerStyle}
          innerContainerClassName={props.innerContainerClassName}
          showClear={props?.showClear}
          label={props?.label ? props?.label : "Country"}
          error={
            !props?.value
              ? "Please Select " + (props?.label ? props?.label : "Country")
              : ""
          }
          showErr={props?.showErr}
          required={props?.required}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
      {!(COUNTRIES?.length > 0) && (
        <SelectField
          placeholder={props.placeholder}
          containerStyle={props.containerStyle}
          noLabel={props.noLabel}
          plain={props.plain}
          style={props?.style}
          innerContainerClassName={props.innerContainerClassName}
          className={props?.className}
          onChange={props?.onChange}
          value={props?.value}
          showClear={props?.showClear}
          label={props?.label ? props?.label : "Country"}
          isDisabled={true}
          error={
            props?.value?.length === 0
              ? "Please Select " + (props?.label ? props?.label : "Country")
              : ""
          }
          showErr={props?.showErr}
          inline={props?.inline}
          borderNone={props?.borderNone}
          RightComponent={props?.RightComponent}
          onClickRight={props?.onClickRight}
        />
      )}
    </React.Fragment>
  );
};

export default observer(CountrySelect);
