import React from "react";
import { Button, Col } from "reactstrap";
import { ThreeBounce } from "better-react-spinkit";

export default function SaveButton(props) {
  const { label = "Add User", className = "" } = props
  // const isEdit = props.data && props.data._id; 
  const isEdit = props?.id
  const loading = props?.loading
  const loadingColor = props?.loadingColor

  return (
    <Col className={`d-flex justify-content-end p-4 ${className}`} 
    // style={{ right: "20%", position: "relative", bottom: "30px", ...props?.style }}
    >
      {loading ?
        <ThreeBounce size={20} color={loadingColor ? loadingColor : "#9F73AB"} />
        : <Button className="col-sm-2 px-4" style={{ width: "auto", background: "#FFCA59", color: "black", border: "none" }} onClick={props.onClick} disabled={props?.disabled}>
          {isEdit ? "Save" : label}
        {/* {label} */}
        </Button>}
    </Col>
  );
}
