import React from "react";

const DeleteLink = (props) => {
  return (
    <i
      role="button"
      className="mdi mdi-delete-outline text-danger font-size-18 mx-2"
      onClick={props?.onClick}
      style={{...props?.style}}
    />
  );
};
export default DeleteLink;
