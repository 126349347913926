import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./SupervisorConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 20;
  clientsByZone = [];
  citiesByZone = [];
  avaiableZones = [];
  downloadFilterOption = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async function (filter) {
    const response = await  doGET(
      ENDPOINTS.grid(this.page, this.rows,filter));
    if (response.status === 200) {

      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
    return response?.data

  };

  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id));
    if (response?.status === 200) {
      return response?.data;
    }
  };

  getKey = async function () {
    try {
      const response = await doGET(ENDPOINTS.getKey);

      return response?.data;
    } catch (error) {
      return error;
    }
  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.saveNew, data);
    this.fetch();

    console.log(response, "response");

    return response?.data?._id;
  };
  update= async function (data,id) {
    const response = await doPOST(ENDPOINTS.update(id), data);
    this.fetch();

    console.log(response, "response");

    return response?.data?.roles;
  };
  delete = async function (id) {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };

  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = '';
  }
  approve = async function (id, val) {
    const response = await doPOST(ENDPOINTS.approve(id));
    if (response.status === 200) {
      this.fetch();
    }
  };
  getImg = async function (url) {
    try {
      return await doGET(ENDPOINTS.geturl(url));
    } catch (error) {
      return error;
    }
  }
  downloadByTemplate = async (filterData, template_id) => {
    const response = await doGET(
      ENDPOINTS.download(this.downloadFilterOption, template_id)
    );

    if (response.status === 200) {
      return response;
    }
  };

}

const SupervisorService = new Service();
export default SupervisorService;
