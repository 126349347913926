// import { Card } from '@material-ui/core'
import React from 'react';
import { Button, Card, CardBody, Nav, Navbar } from 'reactstrap'
import RoleCard from './RoleCard'
import './rolesstyle.css'
// import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const data = [
    {
        title: "Customer Manager",
        totalusers: "5",
        activity: "Manages all Customer Data"
    },
    {
        title: "Consultant Manager",
        totalusers: "2",
        activity: "Manages all Consultant Data"

    },
    {
        title: "Review Manager",
        totalusers: "2",
        activity: "Manages all Review Data"

    },
    {
        title: "Customer Support",
        totalusers: "4",
        activity: "Manages all Support Tickets"

    },
    {
        title: "Super Admin",
        totalusers: "1",
        activity: "Manages all User Data"

    },
    {
        title: "Finance",
        totalusers: "1",
        activity: "Manages all Financial Data"

    },
    // {
    //     title: "Offer",
    //     totalusers: "1",
    //     activity: "Manages all Offer Data"

    // },
    {
        title: "Configuration",
        totalusers: "1",
        activity: "Manages all Configuration Data"

    },

]

const Roles = () => {
    return (
        <div>
            {/* <Button style={{ position: "absolute", right: "30px", background: "#E2E1FB", color: "black", border: "none", top: "80px" }} onClick={() => { }}>
                create
            </Button> */}
            {/* <Card
                className="mt-10 mb-10 px-4"
                style={{
                    width: "95%",
                    left: "75.23px",
                    top: "100px",
                    height: "100%",
                    overflow: "auto",
                    position: "absolute",
                    //   maxWidth: "1320px" 
                }}> */}
            <Card
                className="mt-2 p-2"
                style={{
                    height: "100vh",
                    overflow: "auto",
                    //   paddingBottom: "150px"
                }}>

                <Navbar className='px-3'>
                    <Nav className="mr-auto px-4  text-dark" style={{ fontSize: "17px", color: "black" }}>
                        <Link style={{ fontSize: "20px", color: "black" }} className="px-4 " to="/supervisor">Users</Link>
                        <Link style={{ fontSize: "20px", color: "black", borderBottom: "2px solid black" }} className="px-4 " to="/roles">Role</Link>
                    </Nav>
                </Navbar>
                <hr style={{ marginTop: "-9px" }} />
                <CardBody className='card-container gap-2
                '>
                    {
                        data?.map((ele, i) =>
                        (
                            <RoleCard title={ele?.title} activity={ele?.activity} totalusers={ele?.totalusers} />
                        ))
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default Roles