import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UserContext } from "../../../store/context/userContext";

// users
import user4 from "../../../assets/images/imagplaceholder.png"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageComponent from "../../Image/ImageComponent";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const ProfileMenu = (props) => {
  const navigate = useNavigate()
  const imgsrc = localStorage.getItem('profileImage')
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { logout } = useContext(UserContext);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        // const obj = JSON.parse(localStorage.getItem("authUser"))
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        // const obj = JSON.parse(localStorage.getItem("authUser"))
      }
    }
  }, [props?.success]);

  return (
    <React.Fragment>
      <div className="d-flex">
        <ImageComponent source={imgsrc} style={{
          height: "30px",
          width: "30px",
          borderRadius: "30px",
          marginTop: "20px"
        }}

          className="rounded-circle header-profile-user"
        />
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            {/* <img
            className="rounded-circle header-profile-user"
            src={localStorage.getItem("profileImage")
            }
            alt="Header Avatar"
            style={{ marginTop: "-20px" }}
          /> */}
            <span className="d-inline-block ms-1 fw-medium font-size-12">
              <div style={{ marginLeft: "-12px", fontSize: "15px" }}>
                {localStorage.getItem('name')}
                <i className="uil-angle-down d-inline-block font-size-15" style={{ marginTop: "-5px" }}></i>{" "}
              </div>
              <div>
                {/* {getRoles(localStorage.getItem('role') )|| "Admin"} */}
                {/* <i className="uil-angle-down d-inline-block font-size-15" style={{ marginTop: "-5px" }}></i>{" "} */}
              </div>
            </span>
          </DropdownToggle>
          <DropdownMenu className="custom-dropdown-menu"  >
            <Link
              to="/roles"
              onClick={() => {
                navigate('/roles')
                // logout()
              }}
              className="dropdown-item"

            >
              <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
              <span>{props?.t("Role")}</span>
            </Link>
            <Link
              to="/"
              onClick={() => {
                navigate('/login')
                logout()
              }}
              className="dropdown-item"

            >
              <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
              <span>{props?.t("Logout")}</span>
            </Link>
            <div className=" me-1 text-muted font-size-10 text-end">v1.0.1</div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
