export const ENDPOINTS = {
  grid: (page, rowPerPage, filter) => {
    console.log(filter);
    let url = `/api/profile/admin/supervisors/grid?rowPerPage=` + rowPerPage + "&page=" + page;
    if (filter?.name) {
      url += `&name=${filter?.name}`
    }
    if (filter?.phone) {
      url += `&phone=${filter?.phone}`
    }
    return url;

  },
  get: (id) => `/api/profile/admin/getadmin/${id}`,
  // saveNew: `/api/corp/campus/save`,
  geturl: (url) => `/api/auth/${url}`,
  update: (id) => `/api/profile/admin/update/${id}`,
  getKey: "/api/supervisor/getKeySecret",
  saveNew: "/api/profile/admin/create",
  approve: (id) => `/api/profile/admin/status/${id}`,
  delete: (id) => `/api/supervisor/${id}/delete`,
  getClientsByZone: (id) => `/api/zone/${id}/clientByZone?isAPI=true`,
  getCitiesByZone: (id) => `/api/zone/${id}/citiesByZone?isAPI=true`,
  getSupervisorZones: `/api/corp/zones/get`,
  download: (filterUrl, template_id) => {
    let url = `/api/supervisor/download?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  },
};

export const STRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Country Code",
    description: "description...",
    filterLabel: "Country Code",
    filterName: "countryCode",
    type: "text",
  },
  {
    label: "Phone No.",
    description: "description...",
    filterLabel: "Phone No.",
    filterName: "phone",
    type: "text",
  },
  {
    label: " Email",
    description: "description...",
    filterLabel: " Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "Role",
    filterLabel: "Role",
    filterName: "role",
    description: "description...",
    data: [
      {
        label: "Admin",
        value: "5e2699adbe586e42f2be0921",
      },
      {
        label: "Manager",
        value: "5a13e46e3cdea200017398fa",
      },
      {
        label: "Operation",
        value: "5e269db0be586e42f2be092c",
      },
      {
        label: "FHE",
        value: "5c1a0eb53cdea2000169da4b",
      },
      {
        label: "Implant",
        value: "5c87671158251b03c74d5aa3",
      },
      {
        label: "Transport",
        value: "5c1cc85ec07891337de1a573",
      },
      {
        label: "Superuser",
        value: "superuser",
      },
      {
        label: "Accounts",
        value: "5e269dd2be586e42f2be092d",
      },
      {
        label: "test",
        value: "616d876ce7b25946df6096bf",
      },

    ],
    type: "select",
  },
  {
    label: "Zone",
    description: "description...",
    filterLabel: "Zone",
    filterName: "zone",
    type: "zoneSelect",
  },
  {
    label: "Fuel Man",
    description: "description...",
    filterLabel: "Fuel Man",
    filterName: "isFuelMan",
    type: "checkBox",
  },
  {
    label: "Field Manager",
    description: "description...",
    filterLabel: "Field Manager",
    filterName: "isFieldManager",
    type: "checkBox",
  },
  {
    label: "QC Man",
    description: "description...",
    filterLabel: "QC Man",
    filterName: "isQCMan",
    type: "checkBox",
  },
  {
    label: "Transport Manager",
    description: "description...",
    filterLabel: "Transport Manager",
    filterName: "tptMgr",
    type: "checkBox",
  },
  {
    label: "Booker",
    description: "description...",
    filterLabel: "booker",
    filterName: "isBooker",
    type: "checkBox",
  },
  {
    label: "View Own Business Unit",
    description: "description...",
    filterLabel: "View Own Business Unit",
    filterName: "viewOwnBU",
    type: "checkBox",
  },
  {
    label: "from Finance Team",
    description: "description...",
    filterLabel: "from Finance Team",
    filterName: "fromFinance",
    type: "checkBox",
  },
  // {
  //   label: "from Offer Team",
  //   description: "description...",
  //   filterLabel: "from Offer Team",
  //   filterName: "fromOffer",
  //   type: "checkBox",
  // },
  {
    label: "from Configuration Team",
    description: "description...",
    filterLabel: "from Configuration Team",
    filterName: "fromConfiguration",
    type: "checkBox",
  },
];
