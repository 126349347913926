import React from 'react';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'antd/dist/antd.css';
import moment from 'moment';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;


function convertToUnixTimestamp(arr) {
    if (arr === null) {
        return null;
    }
    else {
        // return arr.map(obj => Math.floor(obj.$d.getTime() / 1000));
        return arr.map(obj => moment(obj).unix());
    }
}

const DateRange = ({ defaultValue, disabled, onChange, placeholder }) => {
    return (
        <Space direction="vertical" size={12}>
            <RangePicker
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={(v) => {
                    const unixTime = convertToUnixTimestamp(v);
                    onChange(unixTime)
                }}
            />
        </Space>
    )
}

export default DateRange
