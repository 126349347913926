import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Nav } from "reactstrap";
import consultant from "../../assets/images/consultant.png";
import finance from "../../assets/images/finance.png";
// import offer from "../../assets/images/finance.png";
import configuration from "../../assets/images/finance.png";
import customer from "../../assets/images/customer.png";
import imageplaceholder from "../../assets/images/imagplaceholder.png";
import reviewer from "../../assets/images/reviewer.png";
import superadmin from "../../assets/images/superAdmin.png";
import support from "../../assets/images/support2.png";
import ImageComponent from "../../components/Image/ImageComponent";
import {
  CardThreeBounce,
  Layout
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import SupervisorService from "./SupervisorService";
import { BiSolidEdit } from "react-icons/bi";

const Supervisor = (props) => {
  const { t } = useContext(I18nContext);
  let { id } = useParams();
  if (props?.corpId) id = props?.corpId;
  const { showMessage, showConfirm, showError } = useContext(DialogContext);
  const [selected_id, setSelected_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [search, setSearch] = useState("")
  const [searchbyphone, setSearchbyphone] = useState("")


  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    console.log(search);
    try {
      let filter = {}
      if (search || searchbyphone) {
        filter.name = search
        filter.phone = searchbyphone
      }
      const response = await SupervisorService.fetch(filter);

      setData(response?.rows)
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  const getRolesImages = (roles) => {


    const roleImages = {
      Customer: customer,
      Consultant: consultant,
      Finance: finance,
      // Offer: offer,
      Reviews: reviewer,
      Support: support,
      Admin: superadmin,
      Configuration: configuration
    };
    const imagesToShow = roles?.map((role) => {
      const imageObject = roleImages[role];
      return <img key={role} src={imageObject} alt={role} style={{ height: '20px', marginRight: "10px" }} />;
    });

    return imagesToShow.length > 0 ? imagesToShow : "No images available";
  };


  useEffect(() => {
    fetchData();
  }, [SupervisorService?.page, SupervisorService?.rows]);



  let navigate = useNavigate();

  useEffect(() => {
    if (id !== selected_id) setSelected_id(id);

  }, [id]);



  const onApprove = async (event, id, val) => {
    event.stopPropagation();
    if (
      val == 1
        ? await showConfirm({
          title: "Deactivate?",
          description: "Are you sure you want to Deactivate ?",
        })
        : await showConfirm({
          title: "Activate?",
          description: "Are you sure you want to Activate?",
        })
    ) {
      try {
        await SupervisorService.approve(id);
        if (val == 1)
          showMessage("info", "Deactivated Successfully");
        else
          showMessage("info", "Activated Successfully");

      } catch (e) {
        showError(e);
      }
    }
  };

  const changePagination=()=>{
    if(SupervisorService.page!=1){
      SupervisorService.page=1;
    }
  }

  if (loading) return <CardThreeBounce />;
  return (
    <>

      <Layout
      changePagination={changePagination}
        hideTitleBar
        insidePane={props.insidePane}
        gridLoading={loading}
        navbar={true}
        filterInput={
          true
        }
        filterInput2={
          true
        }
        search2={searchbyphone}
        setSearch2={setSearchbyphone}
        search1={search}
        setSearch1={setSearch}
        onAddClick={() => {
          console.log("ffff");
          navigate(`/supervisor/edit`,);

        }}
        fetchData={fetchData}
        addcontent={"New User"}
        page={SupervisorService.page}
        rows={SupervisorService.rows}
        total={SupervisorService.totalRecords}
        onPageChange={async (page, rows) => {
          await SupervisorService.onPaginationChange(page, rows);
        }}
      >
        <Layout.NavLinks>
          <Nav className="mr-auto px-4 text-dark" style={{ fontSize: "17px", color: "black" }}>
            <Link style={{ fontSize: "17px", color: "black", borderBottom: "2px solid black" }} className="px-4 " to="/supervisor" >Users</Link>
            <Link style={{ fontSize: "17px", color: "black" }} className="px-4 " to="/roles" >Roles</Link>
            <hr />
          </Nav>
        </Layout.NavLinks>
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Email")}</Th>
              <Th>{t("Role")}</Th>
              <Th>{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {SupervisorService.records?.map((supervisor) => (
              <Tr
                key={supervisor?._id}
                style={{ color: !supervisor?.status ? "rgba(183, 184, 193, 1)" : "background: rgba(0, 0, 0, 1)" }}
                className="p-2"
              >
                <Td scope="row">
                  <div className="d-flex d-flex align-items-center">
                    <ImageComponent source={supervisor?.profileImage ? supervisor?.profileImage : imageplaceholder} style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "40px"

                    }} />
                    {/* <img src={supervisor?.profileImage ? `/api/auth/${supervisor?.profileImage}` : imageplaceholder} height="35" width="35" style={{ borderRadius: "30px" }} /> */}
                    <div className="px-4 d-flex align-items-center">
                      {supervisor?.status === 1 ? (
                        <span style={{ color: 'green', marginRight: '6px', fontSize: '20px' }}>●</span>
                      ) : (
                        <span style={{ color: 'red', marginRight: '6px', fontSize: '20px' }}>●</span>
                      )}                      {supervisor?.name}
                    </div>
                  </div>
                </Td>

                <Td>{supervisor?.email}</Td>

                <Td>
                  {supervisor?.roles?.length > 0 ? getRolesImages(supervisor?.roles) : "--"}
                </Td>
                <Td className="float-start mt-2">
                  <div className="d-flex align-items-center justify-content-between" style={{ fontSize: '14px' }}>
                    <div style={{ cursor: 'pointer' }} className="me-3"
                      onClick={
                        () => {
                          setSelected_id(supervisor?._id)
                          navigate(`/supervisor/edit/${supervisor?._id}`,
                            {
                              state: {
                                id: supervisor?._id
                              }
                            });
                        }
                      }

                    >
                      <BiSolidEdit size={18} />
                    </div>
                  </div>


                </Td>

              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>

    </>
  );
};

export default observer(Supervisor);
