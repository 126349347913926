import React from "react"
import ReactDOM from "react-dom/client";
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./store"
import { UserProvider } from "./store/context/userContext";
import { DialogProvider } from "./store/context/DialogContext";
import { I18nProvider } from "./store/context/i18nContext";
import { CookiesProvider } from "react-cookie";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CookiesProvider>
      <UserProvider>
        <I18nProvider>
          <DialogProvider>
            <App />
          </DialogProvider>
        </I18nProvider>
      </UserProvider>
      </CookiesProvider>
    </BrowserRouter>
  </Provider>
)

