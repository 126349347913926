import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import moment from "moment/moment";
import { Layout } from "../../components";

import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import BannerService from "./BannerService";
import "./style.css";
import image from "../../assets/images/imagplaceholder.png";
import ImageComponent from "../../components/Image/ImageComponent";
import { BiSolidEdit } from "react-icons/bi";

const Banner = (props) => {
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [searchbyphone, setSearchbyphone] = useState("");
  const [searchbyname, setSearchbyName] = useState("");
  const [showViewPage, setShowViewPage] = useState(false);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      const response = await BannerService.fetch(filter);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [BannerService?.page, BannerService?.page]);




  let navigate = useNavigate();
  const changePagination=()=>{
    if(BannerService.page!=1){
      BannerService.page=1;
    }
  }
  return (
    <>
      <Layout
        changePagination={changePagination}
        hideTitleBar
        filterInput={false}
        // search1={searchbyname}
        // setSearch2={setSearchbyphone}
        // setSearch1={setSearchbyName}
        // setSearch3={setSearchbyemail}
        fetchData={fetchData}
        onAddClick={() => {
          navigate("/banner/edit");
        }}
        addcontent={"Banner"}
        // showDetailPage={showDetailPage}
        gridLoading={loading}
        rows={BannerService.rows}
        total={BannerService.totalRecords}
        page={BannerService.page}
        showViewPage={showViewPage}
        // viewSection={<ConsultantDetails />}
        title={t("Banner")}
        onPageChange={async (page, rows) => {
          await BannerService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Image")}</Th>
              <Th>{t("Data")}</Th>
              <Th>{t("Status")}</Th>
              <Th>{t("Navigate To")}</Th>
              <Th>{t("Action")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {BannerService?.records?.map((user) => (
              <Tr key={user?._id}>
                <Td>
                  <div className="d-flex ">
                    <div className="px-2 mt-2">{user?.name ? user?.name : '--'}</div>
                  </div>
                </Td>
                <Td>
                  <div className="d-flex my-2">
                    {user?.url ? <ImageComponent
                      style={{
                        width: "200px",
                        height: "100px",
                        // borderRadius: "100px",
                        objectFit: 'contain',
                        // marginRight: "10px"
                      }}
                      source={user?.url} /> : '--'}

                  </div>
                </Td>
                <Td className="align-items-center">
                  {user?.data ? user?.data : "--"}
                </Td>
                <Td>
                  <div className="px-3 d-flex align-items-center">
                    {user?.active === true ? (
                      <span style={{ color: 'green', marginRight: '6px', fontSize: '20px' }}>●</span>
                    ) : (
                      <span style={{ color: 'red', marginRight: '6px', fontSize: '20px' }}>●</span>
                    )}
                  </div>
                </Td>
                <Td>
                  {user.navigateTo ? user.navigateTo : "--"}
                </Td>
                <Td className="float-start align-items-center justify-content-center mt-2">
                    <div style={{ cursor: 'pointer',fontSize: '14px' }} className="d-flex align-items-center justify-content-between me-3  mt-4" onClick={() =>
                      navigate(`/banner/edit/${user?._id}`)
                    }>
                      <BiSolidEdit size={25} />
                    </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>


    </>
  );
};

export default observer(Banner);
