import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext";
import {  InputField } from "../../components";
import logo from "../../assets/images/wishtoklogo.png"
import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
const Login = (props) => {
  let { isCpanelLogin } = props;
  let navigate = useNavigate();
  const loginData = useRef(isCpanelLogin ? {
    email: "",
    password: "",
    captchaQuery: ""
  } : {
    authType: "password",
    countryCode: 91,
    password: "",
    language: "en",
    phone: null,
    email: "",
    emailPhone: "",
  })
  const [isEmail, setIsEmail] = useState(false);
  const { adminLogin } = useContext(UserContext);
  const { showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    authType: "password",
    countryCode: 91,
    password: "",
    language: "en",
    phone: null,
    email: "",
    emailPhone: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };



  const onLogin = async () => {
    if (loading) return;
    if (!validateEmail(loginData.current.email)) {
      setData((data) => { return { ...data, phone: loginData.email, email: null } });
      loginData.current = { ...loginData.current, phone: loginData.current.email, email: null }

    }
    try {
      setLoading(true);
      var response = await adminLogin(loginData.current);
      if (response.status === 200) {
        navigate("/dashboard", { replace: true });
      } else {
        showMessage(
          "error",
          "Error",
          response?.data?.message
        );
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };



  const sentOTP = async () => {
    try {
      const response = await ApiHandler({
        reqParam: { email: loginData.current.email },
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.adminOTP,
      });
      console.log(response.data)
      if (response.status === 200) {
        showMessage("success", "OTP send successfully", "Success")
        return response;
      } else {
        showMessage(
          "error",
          "Error",
          response?.data?.message
        );
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.body.className = "authentication-bg";

    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className='shadow-none'>
                <CardBody className="p-0">
                  <div className="text-center">
                    <img src={logo} height="40" width={""} alt=""/>
                  </div>
                  <div className="text-center mt-4">
                    <p className="text-primary" style={{ fontWeight: 'bold', fontSize: '17px' }}>Sign In</p>
                  </div>
                  <div className="p-2 mt-4">
                    <div className="form-horizontal">
                      <div className="mb-1">
                        <Row>
                          <Col className={`${isCpanelLogin ? "col-lg-12" : isEmail ? 'col-lg-12' : "col-lg-12"} d-flex`}>
                            <InputField
                              value={data?.email}
                              onChange={(v) => {
                                loginData.current = { ...loginData.current, email: v, emailPhone: v }
                                setData({ ...data, email: v, emailPhone: v });
                              }}
                              // style={{
                              //   height: "40px",
                              // }}
                              required
                              label={"Email"}
                              placeholder="Enter email"
                              type="text"
                              error={
                                data?.email?.length === 0
                                  ? "Please enter office name"
                                  : ""
                              }
                              // showErr={hasErr}
                              showClear={true}
                              className="me-2 col-9"
                            />
                            <button onClick={sentOTP} className="btn btn-primary w-100 waves-effect waves-light">Send OTP</button>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-1">
                        <InputField
                          value={data?.password}
                          onChange={(v) => { loginData.current = { ...loginData.current, password: v }; setData({ ...data, password: v }) }}
                          required
                          label={"OTP"}
                          className="col-12 mt-3"
                          placeholder="OTP"
                          type="text"
                          error={
                            data?.password?.length === 0
                              ? "Please enter Password"
                              : ""
                          }
                          // showErr={hasErr}
                          showClear={true}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          onClick={onLogin}
                        >
                          {loading ? (
                            <ThreeBounce size={12} color="#fff" />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()}  Three Friends Information Technology Consultants LLC</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;