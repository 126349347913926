import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import { DeleteLink, EditLink} from '../../components'
import event from "../../assets/images/wishtok.jpeg"
const data = [
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    },
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    },
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    },
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    },
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    },
    {
        title: "Event /Post",
        date: " 02 March 2023",
        desc: "Lorem ipsum dolor sit amet. Eum veniam quod cum blanditiis"
    }

]
const Event = () => {
    return (
        <div>
            <Card style={{ positon: "relative", left: "70.23px", top: "80px" }}>
                <Row>
                    {
                        data?.map((ele, idx) => (
                            <Col className='col-sm-5 mt-4 mb-4'>
                                <div className='d-flex px-4'>
                                    <img alt="" src={event} height="130" width="180" style={{ borderRadius: "10px" }} />
                                    <div className='px-4' >
                                        <div style={{ fontSize: "25px" }}>
                                            {ele?.title}  {idx+1}
                                        </div>
                                        <div className='uil-calendar-alt mt-2'>
                                            {ele?.date}
                                        </div>
                                        <div className='mt-2'>
                                            {ele?.desc}
                                        </div>
                                        <div className='mt-2'>
                                            <EditLink style={{ background: "#FFCA59", padding: "4px", borderRadius: "6px" }} />
                                            <DeleteLink style={{ background: "#EBEBF9", padding: "4px", borderRadius: "6px" }} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Card>
        </div>
    )
}

export default Event