import React, { useState } from "react";
import { InputField, timeStamptoyyyy_mm_dd } from "../../components";

const yyyy_mm_ddToyyyymmdd = (date) => {
  if (!date) return null;
  const parts = date?.split("-");
  if (parseInt(parts[0]) < 1970) return;
  return parts[0] + parts[1]?.padStart(2, "0") + parts[2]?.padStart(2, "0");
};
const yyyymmddToyyyy_mm_dd = (date) => {
  if (!date) {
    return null;
  }
  return date?.slice(0, 4) + "-" + date?.slice(4, 6) + "-" + date?.slice(6, 8);
};

export default function DateSelect(props) {
  //yyyy-mm-dd
  //yyyyymmdd
  const [value, setValue] = useState(
    yyyymmddToyyyy_mm_dd(props?.value?.toString())
  );

  // useEffect(() => {
  //   console.log(props?.value, 'val');
  // }, [])

  // useEffect(() => {
  //   console.log(value, 'useeffect maihu');
  //   const val = yyyy_mm_ddToyyyymmdd(value);
  //   if (val) props?.onChange(parseInt(val));
  //   else props?.onChange(parseInt(""));
  // }, [value]);

  const changeHandler = (v) => {
    if (props?.getTimestamp) {
      const ts = new Date(v).getTime() / 1000
      props?.onChange(ts)
    }
    else {
      const val = yyyy_mm_ddToyyyymmdd(v);
      if (val) props?.onChange(parseInt(val));
      else props?.onChange(parseInt(""));
    }
  }

  return (
    <React.Fragment>
      <InputField
        className={props?.className}
        value={props?.value ? props?.getTimestamp ? timeStamptoyyyy_mm_dd(props?.value) : yyyymmddToyyyy_mm_dd(props?.value?.toString()) : ''}
        required={props?.required}
        label={props?.label}
        isDisabled={props?.isDisabled}
        onChange={(v) => changeHandler(v)}
        error={props?.value?.length === 0 ? "Please Choose a Valid Date" : ""}
        showErr={props.showErr}
        type="date"
        inline={props?.inline}
        borderNone={props?.borderNone}
        RightComponent={props?.RightComponent}
        onClickRight={props?.onClickRight}
      />
    </React.Fragment>
  );
}
