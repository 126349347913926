import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { dateFormat } from "../../util/helper";
import {
    Layout,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ClientDetail from "./ClientDetail";
import ClientService from "./ClientService";

const ClientInvoice = (props) => {
    const { t } = useContext(I18nContext);
    const { id } = useParams()
    const { showError, } = useContext(DialogContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const [fromDate, setFromDate] = useState();
    const [formattedFromDate, setFormattedFromDate] = useState();
    const [formattedToDate, setFormattedToDate] = useState();
    const [toDate, setToDate] = useState();
    const [forDownload, setForDownload] = useState(false);
    const details = useState(location?.state?.data)
    const [searchInvoiceNo, setSearchInvoiceNo] = useState("");
    const [searchOrderType, setSearchOrderType] = useState("");
    const [searchStatus, setSearchStatus] = useState({
        label: "Status",
        value: 0,
    });
    const [orderType, setOrderType] = useState({
        label: "OrderType",
        value: 0,
    });
    // const fetchData = async () => {
    //     try {
    //         if (loading) return;
    //         setLoading(true);
    //         const response = await ClientService.payment(id)
    //         console.log(response);
    //         setData(response)
    //     } catch (e) {
    //         showError(e);
    //     }
    //     setLoading(false);
    // };

    // useEffect(() => {
    //     fetchData();
    // }, [id]);

    const formatDate = (inputDate = 0) => {
        const dateString = inputDate.toString();
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };
    console.log(orderType);
    const fetchData = async ({ isDownload = false }) => {
        // if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            //   if (searchName) {
            //     filter.name = searchName;
            //   }
            if (searchInvoiceNo) {
                filter.invoiceNo = searchInvoiceNo
            }
            if (fromDate || toDate) {
                filter.fromDate = fromDate;
                filter.tillDate = toDate;
            }
            if (orderType.value) {
                filter.orderType = orderType.value
            }

            if (searchStatus.value) {
                filter.searchStatus = searchStatus?.value
            }
            if (isDownload) {
                filter.forDownload = true;
            }
            const response = await ClientService.payment(id, filter);
            console.log(response);
            if (isDownload) {


                const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.rows);
                const link = document.createElement('a');
                link.href = csvContent;
                link.target = '_blank';
                link.download = 'client_invoice.csv';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } else {
                setData(response)
            }
        } catch (e) {
            console.log(e, "================================================");
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData({ isDownload: false });
    }, [ClientService?.invoicePage, orderType, searchStatus]);
    useEffect(() => {
        setFormattedFromDate(formatDate(fromDate));
        setFormattedToDate(formatDate(toDate));

    }, [fromDate, toDate]);
    console.log(fromDate);

    const orderTypeOptions = [
        {
            icon: "",
            label: "All",
            value: 0,
            callback: () => {
                setOrderType({
                    ...orderType,
                    label: "All",
                    value: 0,
                })
            },
        },
        {
            icon: "",
            label: "Chat",
            value: 1,
            callback: () => {
                setOrderType({
                    ...orderType,
                    label: "Chat",
                    value: 1,
                })
            },
        },
        {
            icon: "",
            label: "Voice call",
            value: 2,
            callback: () => {
                setOrderType({
                    ...orderType,
                    label: "Voice call",
                    value: 2,
                })
            },
        },

        {
            icon: "",
            label: "Video Call",
            value: 3,
            callback: () => {
                setOrderType({
                    ...orderType,
                    label: "Video Call",
                    value: 3,
                })
            },
        }
    ]
    const StatusOptions = [
        {
            icon: "",
            label: "All",
            value: 0,
            callback: () => {
                setSearchStatus({
                    ...searchStatus,
                    label: "All",
                    value: 0,
                })
            },
        },
        {
            icon: "",
            label: "Initiated",
            value: 1,
            callback: (e) => {
                setSearchStatus({
                    ...searchStatus,
                    label: "Initiated",
                    value: 1
                })
            },
        },
        {
            icon: "",
            label: "Successful",
            value: 2,
            callback: (e) => {
                setSearchStatus({
                    ...searchStatus,
                    label: "Successful",
                    value: 2
                })
            },
        },
        {
            icon: "",
            label: "Failed",
            value: 3,
            callback: (e) => {
                setSearchStatus({
                    ...searchStatus,
                    label: "Failed",
                    value: 3
                })
            },
        },
        {
            icon: "",
            label: "Manual",
            value: 10,
            callback: (e) => {
                setSearchStatus({
                    ...searchStatus,
                    label: "Manual",
                    value: 10
                })
            },
        }
    ]

    return (
        <>
            <Layout
                action={orderTypeOptions}
                statusAction={StatusOptions}
                orderType={orderType.label}
                filterOrderType
                filterStatus
                search1={searchOrderType}
                searchInvoiceNo={searchInvoiceNo}
                searchStatus={searchStatus.label}
                setSearchStatus={setSearchStatus}
                setSearch1={setSearchOrderType}
                setSearchInvoiceNo={setSearchInvoiceNo}
                InvoiceFilter
                fromToPlaceholder={["From", "To"]}
                showFromToDateFilter
                setToDate={setToDate}
                setFromDate={setFromDate}
                // filterInput={true}
                forDownload={forDownload}
                setForDownload={setForDownload}
                fetchData={fetchData}
                download={true}
                gridLoading={loading}
                showViewPage={true}
                style={{ height: "fit-Content", top: "0px" }}
                viewSection={<ClientDetail data={details} />}
                page={ClientService.invoicePage}
                rows={ClientService.invoiceRows}
                total={ClientService.invoiceTotalRecords}
                onPageChange={async (page, rows) => {
                    await ClientService.onPaginationChangeinvoice(page, rows);
                }}
                title={t("Invoice Records")}
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr >
                            <Th>{t("Invoice No")}</Th>
                            <Th>{t("Date")}</Th>
                            <Th>{t("Status")}</Th>
                            <Th>{t("Credit/Debit")}</Th>
                            <Th>{t("Order Type")}</Th>
                            <Th className="text-center">{t("Amount")}</Th>
                            <Th className="text-center">{t("Message")}</Th>
                            <Th className="text-center">{t("Offer")}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {data?.map((payment, i) => (
                            <Tr
                                key={i} >
                                <Td>
                                    {payment?.txn_no} <br />
                                </Td>
                                <Td style={{ "minHeight": "30px" }}>
                                    {payment?.updatedAt ? dateFormat(payment?.updatedAt, "DD-MM-YYYY HH:MM") : ""}
                                </Td>
                                <Td>
                                    {payment?.status == 1 ? "Initiated" : ""}
                                    {payment?.status == 2 ? "Successful" : ""}
                                    {payment?.status == 3 ? "Failed" : ""}
                                    {payment?.status == 10 ? "Manual" : ""}
                                </Td>
                                <Td>
                                    {payment?.type == 1 ? "Credit" : ""}
                                    {payment?.type == 2 ? "Debit" : ""}
                                </Td>
                                <Td>
                                    {payment?.chatype == 1 ? "Chat" : ""}
                                    {payment?.chatype == 2 ? "Voice Call" : ""}
                                    {payment?.chatype == 3 ? "Video Call" : ""}
                                </Td>
                                <Td className="text-center">
                                    {payment?.amount}
                                </Td>
                                <Td className="text-center">
                                    {payment?.msg}
                                </Td>
                                <Td className="text-center">
                                    {payment?.offerCode}
                                </Td>

                                {/* <Td className="float-start" >
                                    <div className="actionDropdown">

                                        <UncontrolledDropdown className="ms-2">
                                            <DropdownToggle
                                                style={{ padding: "2px", color: "black", background: "white", borderColor: "rgba(0, 0, 0, 0.33)" }}
                                                className="px-2"
                                            >
                                                Action <i className="uil-angle-down d-inline-block font-size-13"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-start">


                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>

                                </Td> */}
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>
        </>
    );
};

export default observer(ClientInvoice);