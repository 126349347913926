import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ConsultantConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';


  paymentRecords = [];
  paymentTotalRecords = 0;
  paymentPage = 1;
  paymentRows = 20;

  constructor() {
    makeAutoObservable(this);
  }

  resetData = () => {
    this.page = 1;
    this.rows = 20;
    this.records = [];
    this.totalRecords = 0;
  }


  resetPaymentData = () => {
    this.paymentPage = 1;
    this.paymentRows = 20;
    this.paymentRecords = [];
    this.paymentTotalRecords = 0;
  }

  fetch = async (filter = null) => {
    let url = `rows=${this.rows}&page=${this.page}`;
    if (filter?.consultant) {
      url += `&name=caseIgnore[${filter?.consultant}]`
    }
    if (filter?.phone) {
      url += `&phone=${filter?.phone}`
    }
    if (filter?.email) {
      url += `&email=caseIgnore[${filter?.email}]`
    }
    if (filter?.searchStatus) {
      if (filter?.searchStatus === 1) {
        url += `&disable=true`
      } else if (filter?.searchStatus === 2) {
        url += `&disable=false`
      }
    }
    if (filter?.fromJoinDate && filter?.tillJoinDate) {
      url += `&joinDate=btw[${filter?.fromJoinDate},${filter?.tillJoinDate}]`
    }
    if (filter?.country) {
      url += `&country=${filter?.country}`
    }
    if (filter?.city) {
      url += `&city=caseIgnore[${filter?.city}]`
    }
    if (filter?.forDownload) {
      url += `&forDownload=${filter?.forDownload}`
    }
    if (filter?.approvalStatus) {
      if (filter?.approvalStatus === 1) {
        url += `&isApproved=true`
      } else if (filter?.approvalStatus === 2) {
        url += `&isApproved=false`
      }
    }
    if (filter?.category) {
      console.log(filter.category)
      if (filter?.category === 1) {
        url += `&chatActive=true`
      } else if (filter?.category === 2) {
        url += `&callActive=true`
      } else if (filter?.category === 3) {
        url += `&videoCallActive=true`
      }
    }
    if (filter?.skill) {
      url += `&skills=in[${filter?.skill}]`
    }
    const response = await doPOST(ENDPOINTS.grid, { filter: url });
    if (response.status === 200) {
      this.records = response.data?.data?.rows;
      if (this.page === 1)
        this.totalRecords = response.data?.data?.total;
    }
    if (!filter?.forDownload) {
      return response?.data
    } else {
      return response;
    }
  };

  // fetch = async (filter) => {
  //   const response = await doGET(
  //     ENDPOINTS.grid(this.page, this.rows, filter));
  //   if (response.status === 200 && !filter.forDownload) {
  //     this.records = response.data.data.rows;
  //     this.totalRecords = response.data.data.records;
  //   }
  //   if (!filter.forDownload) {
  //     return response?.data.data
  //   } else {
  //     return response;
  //   }

  // };


  edit = async function (data, id) {
    const response = await doPOST(ENDPOINTS?.edit(id), data);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getEarnings = async (id) => {
    const response = await doGET(ENDPOINTS.getEarnings(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getOrders = async (id) => {
    const response = await doGET(ENDPOINTS.getOrders(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  getSkills = async () => {
    const response = await doGET(ENDPOINTS.getSkills());
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getTimeSpent = async (id) => {
    const response = await doGET(ENDPOINTS.getTimeSpent(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  payment = async (id, filter) => {
    const response = await doGET(ENDPOINTS.getinvoice(this.paymentPage, this.paymentRows, id, filter));
    if (response.status === 200 && !filter.forDownload) {
      this.paymentRecords = response.data.rows;
      this.paymentTotalRecords = response.data.records;
    }
    if (!filter.forDownload) {
      return response?.data?.rows
    } else {
      return response;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  updatePassword = async (data) => {
    const response = await doPOST(ENDPOINTS.updatePassword, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data.rows ? response.data.rows : response;
    }
  };
  update = async (data, id) => {
    const response = await doPOST(ENDPOINTS.updateDisableField(id), data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  disableALlSelected = async (data) => {
    const response = await doPOST(ENDPOINTS.disableALlSelected, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  delete = async (id) => {
    const response = await doGET(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };
  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    // await this.fetch(this.filterOption);
  };

  onPaymentPaginationChange = async (page, rows) => {
    this.paymentPage = page;
    this.paymentRows = rows;
    this.paymentRecords = [];
    this.paymentTotalRecords = 0;
  };

  bulkUpdate = async function (body) {
    const response = await doPOST(ENDPOINTS.bulkUpdate(), body);
    if (response.status === 200) {
      this.fetch();
    }
  };

  approve = async function (id, val) {
    console.log(id, "+++++", val);
    const response = await doPOST(ENDPOINTS.approve(id), val);
    if (response.status === 200) {
      this.fetch();
    }
  };

  payout = async (data) => {
    const response = await doPOST(ENDPOINTS.payout, data)

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  }

  isPayout = async (data) => {
    const response = await doPOST(ENDPOINTS.isPayout, data)

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  }
  getLanguages = async () => {
    const response = await doGET(ENDPOINTS.getLanguages)
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  }
  getTags = async () => {
    const response = await doGET(ENDPOINTS.getTags)
    if (response.status === 200) {
      return response.data?.rows ? response.data?.rows : response;
    }
  }

}

const ConsultantService = new Service();
export default ConsultantService;
