// import { Card } from '@material-ui/core'
// import React from 'react'
import { Card, CardBody } from 'reactstrap'
import './rolesstyle.css'
const RoleCard = (props) => {
  return (
    <div className='p-4' style={{ height: "fit-content" }}>
      <Card
        className='p-4'
        style={{ minWidth: "300px", }}>
        <h1 className='d-flex justify-content-center '><strong>{props?.title}
        </strong></h1>

        <CardBody className='mt-4'>
          <ul className='role-list'>
            <li className='mt-2'>
              {props?.activity}
            </li>


          </ul>
        </CardBody>

      </Card>
    </div>
  )
}

export default RoleCard