import React from "react";
import { useNavigate } from "react-router-dom";


const EditLink = (props) => {
  let navigate = useNavigate();
  return (
    <div className={props.className} style={{ display: "inline-block", color: "primary", cursor: "pointer", ...props?.style }} onClick={() => { if (props?.to) navigate(props?.to); props?.onClick() }}>
      <i style={{ color: "primary" }} className="uil uil-pen color-primary font-size-18" />
    </div>
  );
};
export default EditLink;
