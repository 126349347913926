import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  changeLayout,
  changeLayoutWidth,
  changeTopbarTheme,
} from "../../store/actions";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./layout.scss"

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: true,
      // isMenuOpened: JSON.parse(localStorage.getItem('isSidebarOpen')) || true,
      title: 'Dashboard'
    };
  }

  componentDidMount() {
    if (this.props?.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);

    document.title = "Wishtok";
    this.props?.changeLayout("horizontal");
    if (this.props?.topbarTheme) {
      this.props?.changeTopbarTheme("dark");
    }
    if (this.props?.layoutWidth) {
      this.props?.changeLayoutWidth(this.props?.layoutWidth);
    }
  }

  openMenu = ({ isMenu, title }) => {
    this.setState({ isMenuOpened: isMenu, title: title });
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="uil-shutter-alt spin-icon"></i>
            </div>
          </div>
        </div>

        <div className="d-flex flex-1 w-100" style={{ position: "fixed" }} id="layout-wrapper">
          <Sidebar openMenu={this.openMenu} />
          <div className={`d-flex flex-column flex-1  ${this.state.isMenuOpened ? "content-slideMargin" : "content-slideMarginReverse"}`}>
            <div className="main-content">
              <header className="px-0 bg-white shadow ">
                <Header
                  theme={this.props?.topbarTheme}
                  isMenuOpened={this.state.isMenuOpened}
                  openLeftMenuCallBack={this.openMenu}
                  title={this.state.title}
                />
              </header>
              {this.props?.children}
            </div>
          </div>
        </div>

        {/* {this.props?.showRightSidebar ? <Rightbar /> : null} */}
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout));
