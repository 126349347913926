import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './ImageUpload.css';
import imageplaceholder from '../../assets/images/imagplaceholder.png'
import ImageComponent from '../../components/Image/ImageComponent';
import { isObjectValidate } from '../../util/helper';

const ImageUploader = (props) => {
    const [image, setImage] = useState(props?.src ? `${props?.src}` : "");

    const onDrop = (acceptedFiles) => {
        props.setImgurl(acceptedFiles[0])

        setImage(URL.createObjectURL(acceptedFiles[0]));
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (
        <>
            <div className={`image-upload ${isDragActive ? 'image-upload--active' : ''}`} {...getRootProps()}>
                <input {...getInputProps()} accept="image/*" />
                <i className='uil-pen pencil-icon' />
                <div className="image-upload__preview">
                    {isObjectValidate(props?.imgurl) ? <ImageComponent source={image} style={{
                        objectFit: 'contain',
                        height: 100,
                        width: 100
                    }} /> :
                        <img src={image} alt="" style={{
                            objectFit: 'cover'
                        }} />}

                </div>
            </div>
        </>
    );
}

export default ImageUploader