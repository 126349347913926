import React from "react";
import { Card, Col, Row } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import moment from "moment";
import SupportService from "./SupportService";



const SupportCard = (props) => {

    const { ele } = props
    const navigate = useNavigate()

    const getDate = (val) => {
        const date = new Date(val);
        const options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate
    }
    const getStatus = (val) => {
        if (val == 1)
            return "Open";
        if (val == 2)
            return "Closed"
    }


    return <Card
        onClick={() => navigate(`/supportdetail/${ele?.ticketNo}/${ele?._id}`, { state: { ticketNo: ele?.ticketNo } })}
        className='col-sm-12 box-shadow'
        style={{ width: "100%", height: "170px", fontSize: "16px", cursor: "pointer" }}
    >
        <Row className='px-2'>
            <Col>
                <h2>
                    <strong style={{ color: "black" }}>
                        Ticket ID : <span style={{ color: "rgba(26, 177, 31, 1)" }}>{ele?.ticketNo} </span>
                    </strong>
                </h2>
            </Col>
            <Col style={{ textAlign: "end" }}>
                {SupportService.userMap?.[ele?.user_id]?.name}
            </Col>
        </Row>
        <Row className='mt-1 pe-2' >
            <Col>
                <h2><strong className='p-2'>{ele?.heading}</strong></h2>
            </Col>
            <Col style={{ textAlign: "end" }}>
                {SupportService.userMap?.[ele?.user_id]?.phone}
            </Col>
        </Row>
        <Row className='mt-1 pe-2'>
            <Col>
                <h4 className='p-2'>{ele?.desc}</h4>
            </Col>
            <Col style={{ textAlign: "end", color: "black", fontWeight: "400" }}>
                Status : <span style={{ color: "#756464" }}>{getStatus(ele?.status)}</span>
            </Col>
        </Row>
        <Row className='p-2' style={{ textAlign: "end", fontWeight: 400 }}>
            <Col>
                {/* {getDate(ele?.createdAt)} */}
                {moment.unix(ele?.createdAt).format('lll')}
            </Col>
        </Row>
    </Card>
};

export default SupportCard;
