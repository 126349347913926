import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CrossButton } from '../../components';
import { doGET } from '../../util/httpUtil';
import { ENDPOINTS } from './ConsultantConstant';
import { ThreeBounce } from 'better-react-spinkit';

const SummaryModal = ({ modalIsOpen, setModalIsOpen, id }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModalIsOpen({
            visible: false,
            id: ""
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await doGET(ENDPOINTS.summary(id));
                setData(res.data?.data[0]);
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        }
        setData({})
        if (!!id)
            fetchData();
    }, [id])

    function formatTime(seconds) {
        if (seconds < 60) {
            return `${seconds?.toFixed(2)} sec`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60).toFixed(2);
            return `${minutes} min`;
        } else {
            const hours = Math.floor(seconds / 3600).toFixed(2);
            return `${hours} hr`;
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            style={{
                overlay: {
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    margin: 'auto',
                    width: 500,
                    borderRadius: "16px",
                },
            }}
        >
            <div style={{ cursor: "pointer" }}>
                <CrossButton onClick={closeModal} />
            </div>
            <h4 style={{ fontWeight: '600' }}>Consultant Summary</h4>
            {
                loading ? <ThreeBounce /> : (
                    <table cellPadding={5} className='mt-4' style={{ border: '1px solid grey', width: '100%' }}>
                        <thead style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                            <tr>
                                <th>Metric</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Chat Transactions</td>
                                <td>{data?.chatTransactions ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Voice Call Transactions</td>
                                <td>{data?.voiceTransactions ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Video Call Transactions</td>
                                <td>{data?.videoTransactions ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Total Transactions</td>
                                <td>{data?.totalTransactions ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Missed Call Percentage</td>
                                <td>{data?.missedCallPercentage ?? 0}%</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Average Call Duration</td>
                                <td>{data?.avgCallDurations ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Unique Users</td>
                                <td>{data?.uniqueUsers ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Repeating Users</td>
                                <td>{data?.repeatUsers ?? 0}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Total Online Duration</td>
                                <td>{formatTime(data?.totalOnlineDuration ?? 0)}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid lightgrey' }}>
                                <td>Average Response Time</td>
                                <td>{formatTime(data?.avgResponseTime ?? 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            }
        </Modal>
    )
}

export default SummaryModal;