import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import OfferService from "./OfferService";
import "./style.css";
import { BiSolidEdit } from "react-icons/bi";

const Offer = () => {
  const { t } = useContext(I18nContext);
  const { showError, } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [showViewPage, setShowViewPage] = useState(false);
  const [searchStatus, setSearchStatus] = useState({
    label: "All",
    value: "",
  });

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      if (searchStatus?.value) {
        filter.status = searchStatus?.value
      }
      const response = await OfferService.fetch(filter);
      console.log(response);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [OfferService?.page, OfferService?.rows, searchStatus]);

  const color = (ent) => {
    return (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: ent?.active === true ? "green" : "red",
            fontSize: "13px",
            marginRight: "10px",
            float: "left",
          }}
        />
      </>
    );
  };
  const colormulti = (ent) => {
    return (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: ent?.multiUse === true ? "green" : "red",
            fontSize: "13px",
            marginRight: "10px",
            float: "left",
          }}
        />
      </>
    );
  };

  let navigate = useNavigate();

  const changePagination=()=>{
    if(OfferService.page!=1){
      OfferService.page=1;
    }
  }

  const StatusOptions = [
    {
      label: "All",
      value: "",
      callback: () => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "All",
          value: "",
        })
      },
    },
    {
      label: "Active",
      value: "true",
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Active",
          value: "true"
        })
      },
    },
    {
      label: "Expired",
      value: "false",
      callback: (e) => {
        changePagination();
        setSearchStatus({
          ...searchStatus,
          label: "Expired",
          value: "false"
        })
      },
    },
  ]

  return (
    <>
      <Layout
        filterStatus
        statusAction={StatusOptions}
        searchStatus={searchStatus.label}
        changePagination={changePagination}
        noFilter
        hideTitleBar={true}
        fetchData={fetchData}
        onAddClick={() => {
          navigate("/offer/edit");
        }}
        addcontent={"Add Offer"}
        gridLoading={loading}
        rows={OfferService.rows}
        records={OfferService.records}
        total={OfferService.totalRecords}
        page={OfferService.page}
        showViewPage={showViewPage}
        // title={t("Offers")}
        onPageChange={async (page, rows) => {
          await OfferService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Name")}</Th>
              <Th>{t("Notes")}</Th>
              <Th>{t("MultiUse")}</Th>
              <Th>{t("Active")}</Th>
              <Th>{t("Kind")}</Th>
              <Th>{t("Expiry")}</Th>
              <Th>{t("Amount")}</Th>
              <Th>{t("Used By")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {OfferService?.records?.map((user) => (
              <Tr key={user?._id}>
                <Td >
                  <div className="d-flex align-items-center !important" >
                    <div className="px-2 py-2">{user?.name}</div>
                  </div>
                </Td>
                <Td >
                  <div className="mt-2">
                    {user.notes}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {colormulti(user)}
                  </div>


                </Td>
                <Td >
                  <div className="mt-2">
                    {color(user)}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {user?.kind}     {user?.kind == "recharge" ? `${user?.subKind == "instantrecharge" ? "(instant)" : ""}` : ""}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {
                      user?.expiry != undefined ? (
                        (user?.expiry?.toString())?.slice(6, 8) +
                        '-' +
                        (user?.expiry?.toString())?.slice(4, 6) +
                        '-' +
                        (user?.expiry?.toString())?.slice(0, 4)
                      ) : (
                        '--'
                      )}
                  </div>


                </Td>
                <Td >
                  <div className="mt-2">
                    {user?.amount}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {OfferService.usageCount?.[user?.name] ?? 0}
                  </div>

                </Td>
                <Td className="float-start justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mt-2" style={{ fontSize: '14px' }}>
                    <div style={{ cursor: 'pointer' }} className="me-3" onClick={() =>
                      navigate(`/offer/edit/${user?._id}`)
                    }>
                      <BiSolidEdit size={18} />
                    </div>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout >
    </>
  );
};

export default observer(Offer);
