import { ThreeBounce } from 'better-react-spinkit'
import React, { useContext } from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import { UserContext } from '../../store/context/userContext';

export default function ThreeBounceLoader() {
    const { showLoader } = useContext(UserContext);
    return (
        <>
            {showLoader ?
                <div className="d-flex align-items-center justify-content-center " style={{
                    position: "fixed", inset: 0, background: 'rgba(0, 0, 0, 0.5)', zindex: 99999
                }}>
                    <ThreeBounce size={20} color="#5b73e8" />
                </div> : null}
        </>
    )
}
