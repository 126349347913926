import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Col, Nav, Row } from 'reactstrap';
import {
    Layout,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import NotificationService from './NotificationService';

const Notification = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const {  showError } = useContext(DialogContext);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await NotificationService.fetch();
            setData(response?.rows)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const getTime = (val) => {
        const date = new Date(val);
        const formattedDate = date.toLocaleString();
        return (formattedDate);
    }
    return (
        <div>

            <Layout>
                <Layout.NavLinks>
                    <Nav className="mr-auto px-4 text-dark" style={{ fontSize: "17px", color: "black" }}>
                        <Link style={{ fontSize: "17px", color: "black" }} className="px-4 " to="/notification" >View All</Link>
                        <Link style={{ fontSize: "17px", color: "black" }} className="px-4 " to="/notification" >Tasks</Link>
                        <Link style={{ fontSize: "17px", color: "black" }} className="px-4 " to="/notification" >Reminders</Link>
                    </Nav>
                </Layout.NavLinks>
                <Layout.TheadFull style={{ position: "relative", top: "-50px" }}>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>Notifications</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {data?.map((ele, idx) => (
                            <Tr key={idx}>
                                <Td className="float-start w-100">
                                    <Row className='p-2'>
                                        <Col className='col-sm-1 uil-bell text-align-center' style={{ fontSize: "20px", backgroundColor: "rgba(255, 182, 25, 0.5)", width: "33px", borderRadius: "30px" }}>
                                        </Col>
                                        <Col className='col-sm-9' style={{ fontSize: "15px" }}>
                                            {ele?.msg}
                                        </Col>
                                        <Col className='col-sm-2' style={{ fontSize: "15px" }}>
                                            {getTime(ele?.at)}
                                        </Col>
                                    </Row>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>
        </div>
    )
}

export default Notification