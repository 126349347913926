import React, { useState, useEffect } from "react";
import { doGET } from "../../util/httpUtil";
import { useCookies } from 'react-cookie';

export const I18nContext = React.createContext({});

export const I18nProvider = (props) => {

    const [translations, setTranslations] = useState({});
    const [cookies, setCookie] = useCookies(['user']);
    const [lang, setLang] = useState(cookies?.Lang?.lang ? cookies?.Lang?.lang : "hi");
    const [calledKeys, setCalledKeys] = useState([]);

    const fetchAllTranslations = async () => {
        // console.log("fetching all "  + lang +" transaltions" );
        const t = await doGET("/api/o/i18n/" + lang + "/all");
        setTranslations({ ...translations, [lang]: t.data });
    };

    const changeLang = (language) => {
        // console.log(language)
        setCookie('Lang', language, { path: '/' });
        setLang(language.lang);
    }

    useEffect(() => {
        // console.log( translations[lang]);
        // console.log(lang);
        setCalledKeys([])
        if (
            translations[lang] == undefined ||
            translations[lang] == null || translations[lang] == {}
        ) {
            // console.log("all");
            // fetchAllTranslations();
        }
    }, [lang]);

    const t = (key) => {
        if (lang == undefined || lang === "en") {
            return key;
        }
        if (
            translations[lang] != undefined &&
            translations[lang] != null &&
            translations[lang] != {} &&
            translations[lang][key] != undefined
        ) {
            // console.log("success");
            return translations[lang][key];
        }

        if (
            translations[lang] == undefined ||
            translations[lang] == null || translations[lang] == {}
        ) {
            // console.log("all");
            // fetchAllTranslations();
        }

        if (
            translations[lang] != undefined &&
            translations[lang] != null &&
            translations[lang] != {} &&
            translations[lang][key] == undefined
            // && !calledKeys?.includes(key)
        ) {
            // console.log("api");
            // setCalledKeys([...calledKeys, key])
            doGET("/api/o/i18n/translate?lang=" + lang + "&key=" + key);
        }
        return key;
    };

    return (
        <I18nContext.Provider
            value={{
                lang,
                t,
                changeLang,
                translations
            }}
        >
            {props?.children}
        </I18nContext.Provider>
    );
};
