import React from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from "reactstrap";

export default function DropDown(props) {
    const { setDrp_primary = false, drp_primary, label, action, className, menuClassName = "", borderRadius, filterName, style = null, menuStyle, ref } = props;
    return (
        <>
            <Dropdown
                onClick={(e) => e.preventDefault()}
                isOpen={drp_primary}
                toggle={() => setDrp_primary(!drp_primary)}
            >
                <DropdownToggle
                    ref={ref}
                    onClick={(e) => e.preventDefault()}
                    tag="button"
                    className={className}
                    borderRadius={borderRadius}
                    style={{ padding: "0 5px 0 10px", borderRadius: borderRadius ? borderRadius : "0", ...(style ? style : null) }}
                >
                    <span style={{ float: "left", lineHeight: "35px", }}>
                        {label}
                    </span>
                    <span
                        className="bx bx-chevron-down mt-1"
                        style={{ fontSize: "24px", lineHeight: "28px" }}
                    />
                </DropdownToggle>
                <DropdownMenu onClick={(e) => e.preventDefault()} className={`${menuClassName}`} style={{inset:""}}>
                    {action?.map((singleAction, key) => {
                        return (
                            <DropdownItem
                                key={key}
                                onClick={(e) => {
                                    e.preventDefault();
                                    singleAction.callback(singleAction?.value);
                                }}
                                style={{ cursor: "pointer", ...(menuStyle ? menuStyle : null), color: singleAction?.color ? singleAction?.color : "black" }}
                            >
                                {singleAction.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown >
        </>
    );
}
