import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../store/context/i18nContext.js";
import { DialogContext } from "../../../store/context/DialogContext.js";
import RevenueService from "./RevenueService.js";
import { Layout } from "../../../components/index.js";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import GeneralModal from "../../../components/Common/GeneralModal.js";
import { observer } from "mobx-react-lite";

const RevenueGrid = ({ visible, setVisible }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            await RevenueService.fetch();
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [RevenueService.page, RevenueService.rows]);

    const changePagination = () => {
        if (RevenueService.page != 1) {
            RevenueService.page = 1;
        }
    }

    return (
        <>
            <GeneralModal
                visible={visible}
                setVisible={setVisible}
                contentStyle={{
                    height: "fit-content"
                }}
            >
                <Layout
                    insidePane
                    changePagination={changePagination}
                    hideTitleBar={true}
                    disableMultiple={"Disable"}
                    gridLoading={loading}
                    rows={RevenueService.rows}
                    total={RevenueService.totalRecords}
                    page={RevenueService.page}
                    onPageChange={async (page, rows) => {
                        await RevenueService.onPaginationChange(page, rows);
                    }}
                >
                    <Layout.TheadFull>
                        <Thead className="thead-dark">
                            <Tr>
                                <Th>{t("User Id")}</Th>
                                <Th>{t("Transaction Type")}</Th>
                                <Th>{t("Coupon")}</Th>
                                <Th>{t("Discount Amount")}</Th>
                                <Th>{t("Total Amount")}</Th>
                                <Th><div style={{ textAlign: 'end' }}>{t("Amount After Discount")}</div></Th>
                            </Tr>
                        </Thead>
                    </Layout.TheadFull>

                    <Layout.TbodyFull>
                        <Tbody style={{ maxHeight: "100px", overflowY: "scroll" }}>
                            {RevenueService?.records?.map((user, index) => (
                                <Tr key={index}>
                                    <Td>{RevenueService.userMap?.[user?.user_id]}</Td>
                                    <Td>{user?.type === 1 ? "Credit" : "Debit"}</Td>
                                    <Td>{user?.offerCode ?? "--"}</Td>
                                    <Td>{user?.offerAmount ?? 0}</Td>
                                    <Td>{user?.amount}</Td>
                                    <Td>{user?.amount - (user?.offerAmount ?? 0)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Layout.TbodyFull>
                </Layout>
            </GeneralModal>
        </>
    );
};

export default observer(RevenueGrid);