import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./DashboardConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
    records = [];
    totalRecords = 0;
    STRUCTURE = [];
    page = 1;
    rows = 20;
    filterOption = '';
    error = "";
    downloadFilterOption = '';
    consultantMap = {}

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filter) => {
        const response = await doPOST(
            ENDPOINTS.grid(this.page, this.rows, filter));
        if (response.status === 200) {
            this.records = response.data.rows;
            this.totalRecords = response.data.records;
        }
        return response?.data
    };

    edit = async function (data, id) {
        const response = await doPOST(ENDPOINTS?.edit(id), data);
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };
    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, data);
        await this.fetch();
        console.log(response.status);
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };
    updatePassword = async (data) => {
        const response = await doPOST(ENDPOINTS.updatePassword, data);
        await this.fetch();
        console.log(response.status);
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };
    delete = async (id) => {
        const response = await doGET(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch();
        }
    };
    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        await this.fetch(this.filterOption);
    };

    approve = async function (id, val) {
        console.log(id, "+++++", val);
        const response = await doPOST(ENDPOINTS.approve(id), val);
        if (response.status === 200) {
            this.fetch();
        }
    };

    getSales = async (filter) => {
        const response = await doGET(ENDPOINTS?.sales(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    getPayments = async (filter) => {
        const response = await doGET(ENDPOINTS?.payments(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    earnings = async (filter) => {
        const response = await doGET(ENDPOINTS?.earnings(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }
    orderStats = async (filter) => {
        const response = await doGET(ENDPOINTS?.orderStats(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }
    consultantEarnings = async (filter) => {
        const response = await doGET(ENDPOINTS?.consultantEarnings(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }
    customerExpenditure = async (filter) => {
        const response = await doGET(ENDPOINTS?.customerExpenditure(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }
    walletAmount = async (filter) => {
        const response = await doGET(ENDPOINTS?.walletAmount(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    revenue = async (filter) => {
        const response = await doGET(ENDPOINTS?.revenue(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }


    orders = async (filter) => {
        const response = await doGET(ENDPOINTS?.orders(filter));
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    totalConsultants = async () => {
        const response = await doGET(ENDPOINTS.totalConsultants);
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    totalUsers = async () => {
        const response = await doGET(ENDPOINTS.totalUsers);
        if (response.status == 200) {
            return response.data ? response.data : response;
        }
    }

    totalConsultantEarnings = async () => {
        const response = await doGET(ENDPOINTS.totalConsultantEarning);
        if (response.status == 200) {
            return response.data?.data ?? []
        }
    }

    fetchConsultants = async (ids = []) => {
        const resp = await doPOST(ENDPOINTS.consultantsById, {
            filter: ids?.length > 0 ? `_id=in[${ids.join(",")}]&rows=-1` : ""
        })
        if (resp.status === 200) {
            this.consultantMap = resp.data?.data?.rows.reduce((acc, item) => {
                acc[item._id] = `${item?.fname ?? ""} ${item?.lname ?? ""}`;
                return acc;
            }, {});
        }
    }
}

const DashboardService = new Service();
export default DashboardService;
