import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import {
    InputField, SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import CurrencyRateService from "./CurrencyRateService";


const EditCurrencyRate = (props) => {
    const [data, setData] = useState();

    const { name } = useParams();
    const { showMessage, showError, showConfirm, showAlert } =
        useContext(DialogContext);

    const getExchangeRate = async () => {
        try {
            let response = {};
            if (name) response = await CurrencyRateService.getExchangeRate(name);
            setData(response?.data[0]);
        } catch (error) { }
    };

    const get = async () => {
        try {
            let response = {};
            const query = `?name=${name}`
            if (name) response = await CurrencyRateService.grid(query);
            setData(response?.data[0]);
        } catch (error) { }
    };

    const onApprove = async (event, id, val) => {
        event.stopPropagation();
        const userApproved = {
            val,
        };
        if (
            val === 1
                ? await showConfirm({
                    title: "Approve Consultant?",
                    description: "Are you sure you want to approve this Consultant?",
                })
                : await showConfirm({
                    title: "Unapprove Consultant?",
                    description: "Are you sure you want to Unapprove this Consultant?",
                })
        ) {
            try {
                await CurrencyRateService.approve(id, userApproved);
                getExchangeRate();

                showMessage("info", "Consultant Approved  Successfully");
                // fetchData();
            } catch (e) {
                showError(e);
            }
        }
    };
    useEffect(() => {
        get();
    }, [name]);

    const submitForm = async () => {
        try {
            let response;
            response = await CurrencyRateService.save(data);
            get();

            showAlert({
                title: name
                    ? "Currency updated successfully"
                    : "New Cofiguration has been Created Successfully",
            });
        } catch (err) {
            showError(err?.message);
        }
    };
    return (
        <React.Fragment>
            <Card
                className="mt-3"
                style={{
                    height: "100%",
                    overflow: "auto",
                    // maxWidth: "1320px",
                }}
            >
                <CardBody
                    className="row py-3"
                // style={{ top: "10%", position: "relative", width: "88%" }}
                >
                    <div className="d-flex justify-content-between mt-3 col-6">
                        <Col className="col-sm-4">
                            <Label>Currency Name</Label>
                            <Row>
                                <InputField
                                    required={true}
                                    placeholder="Name"
                                    value={data?.name}
                                    onChange={(v) => setData({ ...data, name: v })}
                                    maxlength={3}
                                />
                            </Row>
                        </Col>
                        <Col className="col-sm-4">
                            <Label>Exchange Rate</Label>
                            <Row>
                                <InputField
                                    required={true}
                                    placeholder="Rate"
                                    value={data?.rate}
                                    onChange={(v) => setData({ ...data, rate: v })}
                                    type="number"
                                />
                            </Row>
                        </Col>
                        <Col className=" col-sm-2">
                            <SaveButton
                                // style={{ right: "0%" }}
                                onClick={submitForm}
                                label={name ? "Update" : "Save"}
                                disabled={data ? false : true}
                            />
                        </Col>
                    </div>



                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default observer(EditCurrencyRate);
