import React from 'react'

export default function CrossButton(props) {

    const { onClick = () => { } } = props;
    return (
        <div className="edit_cross d-none d-md-block" onClick={onClick} style={{ cursor: "pointer" }}>
            <i style={{ fontSize: "25px" }} className="dripicons-cross" />
        </div>
    )
}


{/* <div className="cross-map d-block" onClick={() => MapService.toggleMap()} style={{ cursor: "pointer" }}>
                <i style={{ fontSize: "25px" }} className="dripicons-cross" />
            </div> */}
