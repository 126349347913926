import React, { useContext, useEffect, useState } from 'react'
import { CardBody, Label } from 'reactstrap';
import DashboardService from './DashboardService';
import { DialogContext } from '../../store/context/DialogContext';
import Modal from 'react-modal';
import { CrossButton } from '../../components';

const CountsRow = () => {
    const [totalConsultantCount, setTotalConsultantCount] = useState({});
    const [totalUserCount, setTotalUserCount] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { showError } = useContext(DialogContext);

    const totalConsultants = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await DashboardService.totalConsultants();
            setTotalConsultantCount(response?.data)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    }

    const totalUsers = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await DashboardService.totalUsers();
            const result = response?.data?.reduce((acc, item) => {
                acc[item.type] = item.data;
                return acc;
            }, {});
            setTotalUserCount(result)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    }

    useEffect(() => {
        Promise.all([
            totalConsultants(),
            totalUsers()
        ])
    }, [])

    return (
        <>
            <CardBody>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="d-flex" style={{ cursor: 'pointer' }} onClick={openModal}>
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalConsultantCount?.totalConsultants}
                            </strong>
                            <br />
                            <h4>
                                Consultants
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.uniqueUserCount}
                            </strong>
                            <br />
                            <h4>
                                Unique Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.transactingUserCount}
                            </strong>
                            <br />
                            <h4>
                                Transacting Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.uniqueTransactingUserCount}
                            </strong>
                            <br />
                            <h4>
                                Unique Transacting Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.totalUserCount}
                            </strong>
                            <br />
                            <h4>
                                Total Users
                            </h4>
                        </h2>
                    </div>
                </div>
            </CardBody>


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        margin: 'auto',
                        width: "500px",
                        borderRadius: "16px",
                    },
                }}
            >
                <div style={{ cursor: "pointer" }}>
                    <CrossButton onClick={() => { setModalIsOpen(false) }} />
                </div>
                <h4 style={{ fontWeight: '600' }}>Category-Wise Consultant Overview (Total: {totalConsultantCount?.totalConsultants})</h4>
                {
                    totalConsultantCount?.categoryWiseConsultants?.map((e, i) => {
                        return <p key={i} style={{
                            fontWeight: '500',
                            fontSize: 13,
                            marginTop: 10,
                        }}>
                            {e?._id ? <>{e?._id}: <Label className=" px-3">{e?.count}</Label></> : null}
                        </p>
                    })
                }
            </Modal>
        </>
    )
}

export default CountsRow;