import React from "react";

const Checkbox = (props) => {
  return (
    <div className="form-check">
      <input
        onChange={props?.onClick}
        checked={props?.checked}
        type="checkbox"
        className="form-check-input"
        id={props?.id}
      />
      <label className="form-check-label px-2 py-1" htmlFor={props?.id}>
        {props?.label}
      </label>
      <span className="mx-4">{props?.desc && props?.desc}</span>
    </div>
  );
};
export default Checkbox;
