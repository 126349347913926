import React from "react";

function SendIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="30"
            fill="none"
            style={{ position: "relative", top: "13px", right: "-6px" }}
            viewBox="0 0 20 20"
        >
            <path
                fill="#000"
                d="M.388 6.983c-.517-.173-.521-.451.01-.629L19.286.051c.523-.175.823.118.677.632l-5.397 18.911c-.149.524-.45.542-.672.045l-3.557-8.014 5.938-7.927-7.917 5.945-7.97-2.66z"
            ></path>
        </svg>
    );
}

export default SendIcon;
