import moment from "moment";
import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import { Badge } from "reactstrap";
import ImageComponent from "../../components/Image/ImageComponent";

const RecordComponent = ({ record }) => {
    const getBackgroundColorClass = (status) => {
        switch (status) {
            case 1:
                return "bg-info"

            case 2:
                return "bg-success"

            case 3:
                return "bg-danger"

            default:
                return "bg-secondary"
        }
    }

    return (
        <Tr>
            <Td >
                <div className="d-flex align-items-center !important" >
                    <ImageComponent source={record?.profileImage} style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "35px"
                    }} />
                    <a
                        href={`/client/payment/${record?.user_id}`}
                        className="px-2 blue-link"
                        style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline important' }}
                    >
                        {record?.userName ? record?.userName : '--'}
                    </a>
                </div>
            </Td>
            <Td >
                <div className="mt-2">
                    {moment.unix(record?.updatedAt ? record?.updatedAt : record?.at).format('DD-MM-YYYY h:mm:ss A')} 
                </div>
            </Td>
            <Td >
                <div className="mt-2">
                    {record?.amount != null ? record?.amount + " AED" : "--"}
                </div>
            </Td>
            <Td >
                <div className="mt-2">
                    {record?.offerAmount != null ? record?.offerAmount + " AED" : "--"}
                </div>
            </Td>
            <Td className="text-center">
                <div className="mt-2">
                    {record?.userType === 1 ? "Consultant" : record?.userType === 2 ? "Customer" : record?.userType === 3 ? "Wishtok" : '--'}
                </div>
            </Td>
            <Td className="text-center">
                <div className="mt-2">
                    {record?.charge_id ? record?.charge_id : '--'}
                </div>

            </Td>
            <Td><div className="mt-2">
                {record?.msg}
            </div></Td>
            <Td>
                <div className="mt-2">
                    {record?.offerCode ? record?.offerCode : '--'}
                </div>
            </Td>
            <Td className="w-100 text-center" >
                <div className="mt-2">
                    <Badge className={`rounded-pill ${getBackgroundColorClass(record?.status)}`}>
                        {record?.status === 1 ? "Initiated" : (record?.status === 2 ? "Successful" : (record?.status === 3 ? "Failed" : record?.status === 10 ? "Manual" : '--'))}
                    </Badge>
                </div>
            </Td>
        </Tr>
    )
};

export default RecordComponent;
