import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import moment from "moment/moment";
import { Layout } from "../../components/index.js";
import { observer } from "mobx-react-lite";
import { DialogContext } from "../../store/context/DialogContext.js";
import { I18nContext } from "../../store/context/i18nContext.js";
import CurrencyRateService from "./CurrencyRateService.js";
// import "./style.css";
// import ConsultantDetails from "./ConsultantDetails";
import image from "../../assets/images/imagplaceholder.png";
import { BiSolidEdit } from "react-icons/bi";


const CurrencyRate = (props) => {
    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [searchbyName, setSearchbyName] = useState("");
    const [data, setData] = useState()

    const [showViewPage, setShowViewPage] = useState(false);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            let query = '';
            if (searchbyName.toUpperCase()) {
                filter.name = searchbyName;
                query = `?name=${searchbyName.toUpperCase()}`
            }
            const response = await CurrencyRateService.grid(query);
            setData(response?.data)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    let navigate = useNavigate();
    const changePagination=()=>{
        if(CurrencyRateService.page!=1){
            CurrencyRateService.page=1;
        }
      }
    return (
        <>
            <Layout
            changePagination={changePagination}
                filterInput={true}
                search1={searchbyName}
                setSearch1={setSearchbyName}
                fetchData={fetchData}
                onAddClick={() => {
                    navigate("/currencyRate/edit");

                }}
                addcontent={"Currency"}
                // showDetailPage={showDetailPage}
                gridLoading={loading}
                rows={CurrencyRateService.rows}
                total={CurrencyRateService.totalRecords}
                page={CurrencyRateService.page}
                showViewPage={showViewPage}
                // viewSection={<ConsultantDetails />}
                title={t("Currency  Rate")}
            // onPageChange={async (page, rows) => {
            //     await CurrencyRateService.onPaginationChange(page, rows);
            // }}
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th className="ps-2">{t("Name")}</Th>
                            <Th>{t("Rate")}</Th>
                            <Th className="float-end">{t("Action")}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody>
                        {data?.length > 0 && data.map((user) => (
                            <Tr key={user?._id} style={{height:"50px"}}>
                                <Td className="ps-2">
                                    {user?.name ? user?.name : "-"}
                                </Td>
                                <Td className="align-items-center">
                                    {user?.rate ? user?.rate : "--"}
                                </Td>
                                <Td className="float-end align-items-center mt-2">

                                    <div className="d-flex align-items-center justify-content-between ms-2" style={{ fontSize: '14px' }}>
                                        <div style={{ cursor: 'pointer' }} className="me-3 align-self-center" onClick={() =>
                                            navigate(`/currencyRate/edit/${user?.name}`)
                                        }>
                                            <BiSolidEdit size={18} />
                                        </div>
                                    </div>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>
        </>
    );
};

export default observer(CurrencyRate);
