import React from 'react';
import Modal from 'react-modal';
import CrossButton from '../Buttons/CrossButton';

const GeneralModal = ({ children, visible, setVisible, contentStyle = {} }) => {
    return (
        <>
            <Modal
                isOpen={visible}
                onRequestClose={() => setVisible(false)}
                contentLabel="Modal"
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        margin: 'auto',
                        borderRadius: "16px",
                        ...contentStyle
                    },
                }}
            >
                <div style={{ cursor: "pointer" }}>
                    <CrossButton onClick={() => setVisible(false)} />
                </div>
                {children}
            </Modal>
        </>
    )
}

export default GeneralModal;