import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import ConsultantService from './ConsultantService';
import imageplaceholder from "../../assets/images/imagplaceholder.png"
import ImageComponent from '../../components/Image/ImageComponent';
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { RiVideoFill } from "react-icons/ri";

const ConsultantDetails = () => {
    const { id } = useParams()
    const [data, setData] = useState({})
    const [earnings, setEarnings] = useState(0)
    const [orders, setOrders] = useState(0)
    const [timespent, setTimespent] = useState(0)

    const getDetails = async () => {
        try {
            let response = {};
            if (id)
                response = await ConsultantService.get(id)
            setData(response)
        } catch (error) {
        }
    }

    const getEarnings = async () => {
        try {
            let response = {};
            if (id)
                response = await ConsultantService.getEarnings(id)
            setEarnings(response.earnings)
        } catch (error) {
        }
    }

    const getOrders = async () => {
        try {
            let response = {};
            if (id)
                response = await ConsultantService.getOrders(id)
            setOrders(response.orders)
        } catch (error) {
        }
    }

    const getTimeSpent = async () => {
        try {
            let response = {};
            if (id)
                response = await ConsultantService.getTimeSpent(id)
            setTimespent(response.timespent)
        } catch (error) {
        }
    }

    useEffect(() => {
        getDetails();
        getEarnings();
        getOrders();
        getTimeSpent();
    }, [id])
    const navigate = useNavigate()

    return (
        <>
            <Card className="mt-3 p-3" style={{ top: "-16px", height: "calc(100vh - 150px)", overflowY: 'scroll' }}>
                <Card.Title className=' mt-3 px-4 py-2' style={{ borderBottom: "0.1px solid black" }}>Consultant Details</Card.Title>
                <div style={{ margin: "10px 0px 0px 110px", width: 100, height: 100, borderRadius: '50%', overflow: 'hidden' }}>
                    {/* <img src={data?.profileImage ? `/api/auth/${data?.profileImage}` : imageplaceholder} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
                    <ImageComponent source={data?.profileImage ? data?.profileImage : imageplaceholder} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <Card.Body>
                    <Card.Title className=" d-flex justify-content-center">{data?.name}</Card.Title>
                    <p className=" d-flex justify-content-center">Vedic</p>
                </Card.Body>
                <div className=' d-flex justify-content-between'>
                    <div className='align-content-center' style={{ border: "0.1px solid #D9D9D9", width: "120px", height: "62px", padding: "12px", marginLeft: "4px", borderRadius: "6px" }}>
                        <h5>{Math.round(earnings * 100) / 100}</h5>
                        <p style={{ fontSize: "11px" }}>Earnings</p>
                    </div>
                    <div className='align-content-center' style={{ border: "0.1px solid #D9D9D9", width: "120px", height: "62px", padding: "12px", marginLeft: "4px", borderRadius: "6px" }}>
                        <h5>{orders}</h5>
                        <p style={{ fontSize: "11px" }}>Orders</p>
                    </div>

                    <div className='align-content-center' style={{ border: "0.1px solid #D9D9D9", width: "120px", height: "62px", padding: "12px", marginLeft: "4px", borderRadius: "6px" }}>
                        <h5>{timespent ? Math.floor(timespent / 60) : 0}</h5>
                        <p style={{ fontSize: "10px" }}>Time Spent(Mins.) </p>
                    </div>
                </div>
                <div className=' d-flex justify-content-between' style={{ borderBottom: "0.1px solid black" }}>
                    <div>
                        <Card.Title className=" mt-2 px-1 py-2" >
                            Details
                        </Card.Title>
                    </div>
                    <div className='my-1 px-1 py-1'>
                        <Button style={{ background: "#E2E1FB", color: "black", border: "none" }}
                            onClick={() => navigate(`/consultant/edit/${data?._id}`)}>
                            Edit
                        </Button>
                    </div>


                </div>
                <div className='px-2 mt-2'>
                    {/* <p className=" px-">
                        AccountID: <Label className=" px-3">{data?._id}</Label>
                    </p> */}
                    <p className="">
                        Phone: <Label className=" px-3">{data?.countryCode} {data?.phone}</Label>
                    </p>
                    <p className="">
                        Email: <Label className=" px-3">{data?.email}</Label>
                    </p>
                    <p className="">
                        Languages: <Label className=" px-3">{data?.languages?.filter((ele) => ele)?.join(", ")}</Label>
                    </p>
                    <p className="">
                        Skills: <Label className=" px-3">{data?.skills?.filter((ele) => ele)?.join(", ")}</Label>
                    </p>
                    <p className="">
                        Location: <Label className=" px-3">{data?.city} {data?.country ? "," : ""} {data?.country}</Label>
                    </p>
                    <p className="">
                        Payment Method: <Label className=" px-3">Bank Transfer-{data?.currency}</Label>
                    </p>
                </div>
                <Card.Title style={{ borderBottom: "0.1px solid black " }} className="px-1 py-2" >Rate Plan</Card.Title>
                <div className='px-1 mt-2'>
                    <p className="px-1">
                        <IoChatbubbleEllipses size={35} style={{ background: "rgba(255, 182, 25, 0.51)", borderRadius: "30px", padding: "7px", marginRight: "8px" }} />
                        <span> Chat: </span>
                        <Label className="px-1">
                            {data?.currency}
                            {data?.chatRate != null ?
                                <span > {data?.chatRate} /min</span>
                                : ""
                            }
                            {data?.chatRate30Min != null ?
                                <span >, {data?.chatRate30Min} /30 min</span>
                                : ""
                            }
                            {data?.chatRate60Min != null ?
                                <span >, {data?.chatRate60Min} /60 min</span>
                                : ""
                            }
                        </Label>
                    </p>
                    <p className=" px-1">
                        <BiSolidPhoneCall size={35} style={{ background: "rgba(255, 182, 25, 0.51)", borderRadius: "30px", padding: "7px", marginRight: "8px" }} />
                        <span>Call:</span>
                        <Label className="px-1">
                            {data?.currency}
                            {data?.chatRate != null ?
                                <span > {data?.callRate} /min</span>
                                : ""
                            }
                            {data?.chatRate30Min != null ?
                                <span >, {data?.callRate30Min} /30 min</span>
                                : ""
                            }
                            {data?.chatRate60Min != null ?
                                <span >, {data?.callRate60Min} /60 min</span>
                                : ""
                            }
                        </Label>
                    </p>
                    <p className=" px-1">
                        <RiVideoFill size={35} style={{ background: "rgba(255, 182, 25, 0.51)", borderRadius: "30px", padding: "7px", marginRight: "8px" }} />
                        <span >Video Call:</span>
                        <Label className="px-1">
                            {data?.currency}
                            {data?.chatRate != null ?
                                <span > {data?.videoCallRate} /min</span>
                                : ""
                            }
                            {data?.chatRate30Min != null ?
                                <span >, {data?.videoCallRate30Min} /30 min</span>
                                : ""
                            }
                            {data?.chatRate60Min != null ?
                                <span >, {data?.videoCallRate60Min} /60 min</span>
                                : ""
                            }
                        </Label>
                    </p>
                </div>
            </Card></>
    )
}

export default ConsultantDetails