import AddButton from "./Buttons/AddButton";
import MenuButton from "./Buttons/MenuButton";
import BackArrow from "./Buttons/BackArrow";
import BackButton from "./Buttons/BackButton";
import CrossButton from "./Buttons/CrossButton";
import DownloadButton from "./Buttons/DownloadButton";
import DeleteLink from "./Buttons/Icons/deleteButton";
import CopyLink from "./Buttons/Icons/copyButton";
import GeneralLink from "./Buttons/Icons/generalButton";
import CheckButton from "./Buttons/Icons/checkButton";
import EditLink from "./Buttons/Icons/editButton";
import RetryLink from "./Buttons/Icons/retryButton";
import DetailLink from "./Buttons/Icons/detailButton";
import PrintLink from "./Buttons/Icons/printButton";
import MailLink from "./Buttons/Icons/mailButton";
import DownloadLink from "./Buttons/Icons/downloadButton";
import UploadLink from "./Buttons/Icons/uploadButton";
import SaveButton from "./Buttons/SaveButton";
import Breadcrumbs from "./Common/Breadcrumb";
import Pagination from "./Common/Pagination";
import Title from "./Common/Title";
import DateSelect from "./DateSelects/DateSelect";
import { InputField } from "./InputField/InputField";
import { SearchInput } from "./InputField/SearchInput";
import CardThreeBounce from "./Loader/CardThreeBounce";
import Accordion from "./Common/Accordion";
import CalenderLink from "./Buttons/Icons/calenderButton";
import { SelectField } from "./SelectField/SelectField";
import { SelectFieldMultiple } from "./SelectField/SelectFieldMultiple";
import { SelectFieldAdd } from "./SelectField/SelectFieldAdd";
import DynamicSelectField from "./SelectField/DynamicSelectField";
import PhoneNum from "./PhoneNum/PhoneNum";
import Modal from "./Common/Modal";
import Layout from "./Layout/Layout";
import Tab from "./Common/Tab/Tab";
import DeleteButton from "./Buttons/DeleteButton";
import Breadcrumb from "./Breadcrumb/Breadcrumb";

const handleEpandRows = (event, bookingId, expandedRows) => {
  const currentExpandedRows = expandedRows;
  const isRowExpanded = currentExpandedRows.includes(bookingId);
  let obj = {};
  isRowExpanded ? (obj[bookingId] = false) : (obj[bookingId] = true);
  const newExpandedRows = isRowExpanded
    ? currentExpandedRows.filter((id) => id !== bookingId)
    : currentExpandedRows.concat(bookingId);
  return newExpandedRows;
};

const yyyy_mm_ddtoTimeStamp = (a) => {
  if (a) {
    a = a.toString();
    const date = new Date(a);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }
};
const yyyymmddtoTimeStamp = (a) => {
  {
    if (a) {
      a = a.toString();
      const f =
        a?.substring(0, 4) +
        "-" +
        a?.substring(4, 6) +
        "-" +
        a?.substring(6, 8);
      const date = new Date(f);
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      return unixTimestamp;
    }
  }
};
const scrollStyle = {
  minHeight: "85vh",
  // zIndex: "2",
  maxHeight: "85vh",
  // overflowY: "auto",
  // position: "fixed",
};

const itemBarSelectedStyle = {
  backgroundColor: "rgba(159, 115, 169, 0.459)",
  borderLeft: " 3px solid #9f73a9",
  color: "#000",
  fontWeight: 600,
};

const changeValueOfidx = (arr, idx, key, value) => {
  arr[idx] = { ...arr[idx], [key]: value };
  return arr;
};

const swapToIndex = (arr, idx, key) => {
  const a = [...arr];
  var b = a[key];
  a[key] = a[idx];
  a[idx] = b;
  return a;
};

const removeitemOnce = (a, idx) => {
  if (idx > -1) {
    a.splice(idx, 1);
  }
  return a;
};

const decodePolyline = (encoded) => {
  if (encoded == null || encoded.length == 0) return null;

  var polylineStartTime = null;
  if (encoded.startsWith("AAA")) {
    polylineStartTime = parseInt(encoded.substring(3, encoded.indexOf("===")));
    encoded = encoded.substring(encoded.indexOf("===") + 3);
  }

  // array that holds the points

  var points = [];
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;
  var tym = 0;
  while (index < len) {
    var b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63; //finds ascii   //and substract it by 63
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    var dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    if (index === len) break;

    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    var dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    if (polylineStartTime != null) {
      shift = result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var dtym = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      tym = dtym;
    }

    var polylineLoc = { lat: lat / 1e5, lng: lng / 1e5 };
    if (polylineStartTime != null) {
      polylineLoc["time"] = polylineStartTime + tym;
    }
    points.push(polylineLoc);
  }
  return points;
};

const yyyy_mm_ddThh_mmToSeconds = (dateTime) => {
  if (!dateTime) return;
  var parts = dateTime.split("T");
  var dateParts = parts[0].split("-");
  var timeParts = parts[1].split(":");
  var year = parseInt(dateParts[0]);
  if (year < 1970) return;
  var month = parseInt(dateParts[1]) - 1;
  var day = parseInt(dateParts[2]);
  var hours = parseInt(timeParts[0]);
  var minutes = parseInt(timeParts[1]);
  var dateObject = new Date(year, month, day, hours, minutes);
  return dateObject.getTime() / 1000;
};

const secondsToyyyy_mm_ddThh_mm = (seconds) => {
  if (!seconds) return;
  const date = new Date(seconds * 1000);
  var month = (date.getMonth() + 1)?.toString()?.padStart(2, "0");
  var day = date.getDate()?.toString()?.padStart(2, "0");
  var hour = date?.getHours()?.toString()?.padStart(2, "0");
  var minutes = date?.getMinutes()?.toString()?.padStart(2, "0");
  return (
    date.getFullYear() + "-" + month + "-" + day + " - " + hour + ":" + minutes
  );
};

const yyyy_mm_ddThh_mmToTimeStamp = (dateTime) => {
  if (!dateTime) return;
  const parts = dateTime.split("T");
  const dateParts = parts[0].split("-");
  const timeParts = parts[1].split(":");
  if (dateParts[0] < 1970) return;
  const dateObject = new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2]),
    parseInt(timeParts[0]),
    parseInt(timeParts[1])
  );
  return dateObject.getTime();
};

const timestampToyyyy_mm_ddThh_mm = (timestamp) => {
  if (!timestamp) return;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return day + "-" + month + "-" + year + " " + hour + ":" + minutes;
};

const timeStamptoyyyy_mm_dd = (a) => {
  if (a) {
    const date = new Date(a * 1000);
    const dd_mm_yyyy = date.toLocaleDateString();

    const yyyy_mm_dd = dd_mm_yyyy
      .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1")
      .toString();
    let f = yyyy_mm_dd?.substring(0, 4);
    const one = yyyy_mm_dd.indexOf("-");
    const two = yyyy_mm_dd.lastIndexOf("-");
    f +=
      "-" +
      (two - one <= 2
        ? "0" + yyyy_mm_dd?.substring(one + 1, one + 2)
        : yyyy_mm_dd?.substring(one + 1, two)) +
      ("-" +
        ((yyyy_mm_dd.length - two <= 2 ? "0" : "") +
          yyyy_mm_dd?.substring(two + 1)));

    return f;
  }
};
const timeStampToyyyy_mm_ddThhmm = (a) => {
  if (a) {
    return `${new Date(a * 1000).toISOString().substring(0, 16)}`;
  }
};

const yyyy_mm_ddThhmmtoTimeStamp = (a) => {
  if (a) {
    return new Date(a).getTime() / 1000;
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const getDateFromSeconds = (v) => {
  return v ? new Date(parseInt(v) * 1000).toISOString().split("T")[0] : "";
};

const getSecondsFromDate = (v) => {
  const s = new Date(v);
  return parseInt(s.getTime());
};

function toggleFullscreen() {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

const reverse_date_str = (date) => {
  if (date) {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(5, 7);
    const dd = date.slice(8, 10);
    return dd + '-' + mm + '-' + yyyy;
  }
  else return ''
}

const timeStamptoyyyymmdd = (a) => {
  if (a) {
    const date = new Date(a * 1000);
    const dd_mm_yyyy = date.toLocaleDateString();
    const yyyy_mm_dd = dd_mm_yyyy
      .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1")
      .toString();
    let f = yyyy_mm_dd?.substring(0, 4);
    const one = yyyy_mm_dd.indexOf("-");
    const two = yyyy_mm_dd.lastIndexOf("-");
    f +=
      "" + ((yyyy_mm_dd.length - two <= 2 ? "0" : "") +
        yyyy_mm_dd?.substring(two + 1)) + "" + (two - one <= 2
          ? "0" + yyyy_mm_dd?.substring(one + 1, one + 2)
          : yyyy_mm_dd?.substring(one + 1, two));
    return f;
  }
};

export {
  toggleFullscreen,
  getDateFromSeconds,
  getSecondsFromDate,
  Breadcrumbs,
  Pagination,
  EditLink,
  UploadLink,
  DownloadButton,
  DeleteLink,
  PrintLink,
  DownloadLink,
  MailLink,
  AddButton,
  DeleteButton,
  MenuButton,
  SaveButton,
  CrossButton,
  BackButton,
  DetailLink,
  DateSelect,
  CopyLink,
  RetryLink,
  Title,
  InputField,
  SearchInput,
  SelectField,
  SelectFieldMultiple,
  SelectFieldAdd,
  yyyymmddtoTimeStamp,
  BackArrow,
  CheckButton,
  handleEpandRows,
  yyyy_mm_ddtoTimeStamp,
  timeStamptoyyyy_mm_dd,
  formatBytes,
  changeValueOfidx,
  swapToIndex,
  removeitemOnce,
  Accordion,
  CardThreeBounce,
  CalenderLink,
  PhoneNum,
  scrollStyle,
  Modal,
  itemBarSelectedStyle,
  DynamicSelectField,
  Layout,
  Tab,
  decodePolyline,
  yyyy_mm_ddThhmmtoTimeStamp,
  timeStampToyyyy_mm_ddThhmm,
  yyyy_mm_ddThh_mmToSeconds,
  secondsToyyyy_mm_ddThh_mm,
  yyyy_mm_ddThh_mmToTimeStamp,
  timestampToyyyy_mm_ddThh_mm,
  Breadcrumb,
  GeneralLink,
  reverse_date_str,
  timeStamptoyyyymmdd,
};
