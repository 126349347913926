import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ConfigurationConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
    records = [];
    totalRecords = 0;
    STRUCTURE = [];
    page = 1;
    rows = 20;
    filterOption = '';
    error = "";
    downloadFilterOption = '';

    constructor() {
        makeAutoObservable(this);
    }


    save = async (data) => {
        const response = await doPOST(ENDPOINTS.save, data);
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    // onPaginationChange = async (page, rows) => {
    //     this.page = page;
    //     this.rows = rows;
    //     await this.fetch(this.filterOption);
    // };

    // approve = async function (id, val) {
    //     console.log(id, "+++++", val);
    //     const response = await doPOST(ENDPOINTS?.approve(id), val);
    //     if (response.status === 200) {
    //         this.fetch();
    //     }
    // };

    grid = async (query) => {
        const response = await doGET(ENDPOINTS.grid(query));
        if (response.status === 200) {
            return response
        }
    }
    edit = async (data) => {
        const response = await doPOST(ENDPOINTS.edit(), data);
        if (response.status === 200) {
            return response
        }
    }

    getExchangeRate = async (to) => {
        const response = await doGET(ENDPOINTS.getExchangeRate(to));
        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    }

}

const ConfigurationService = new Service();
export default ConfigurationService;
