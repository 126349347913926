import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function SelectButton(props) {
  let navigate = useNavigate();
  return (
    <div
      style={{ display: "inline-block", color: "primary" }}
      onClick={() => {
        if (props?.link) navigate(props?.link);
        props?.onClick();
      }}
    >
      <Button
        type="button"
        className={props?.className ? props.className : "btn ms-2 btn-primary"}
        style={{ height: "30px", width: "60px", padding: "2px" }}
      >
        Select
      </Button>
    </div>
  );
}
