import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import ImageComponent from '../../components/Image/ImageComponent';
const DocumentDownload = ({ documentUrl }) => {
    const [pdfType, setPDFType] = useState(false)
    // const handleDownload = (e) => {
    //     e.preventDefault()
    //     fetch(documentUrl)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const url = URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = documentName;
    //             a.click();
    //             URL.revokeObjectURL(url);
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };
    const handleDownload = (e) => {
        e.preventDefault()
        fetch(documentUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                window.open(url);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };
    const getPDFType = () => {
        let isPDF = documentUrl.includes('.pdf')
        console.log(isPDF, "isPDF");
        setPDFType(isPDF);
    }
    useEffect(() => {
        getPDFType()
    }, [])

    return (
        <>
            {/* {JSON.stringify(pdfType)}
            {
                pdfType == true ?
                    <>
                        <Card className='mt-3 p-3 mx-4' style={{ border: "1px solid black", height: "fitContent", width: "auto", height: "auto" }}>
                            <img src={pdficon} style={{ height: "100px", width: "100px", border: "none" }} />
                            <a style={{ fontSize: "15px" }} className='p-2 text-dark' href='' onClick={handleDownload}>
                                View File
                            </a>
                        </Card>
                    </>
                    : */}
            <Card className='mt-3 p-3' style={{ border: "1px solid black", height: "fitContent", width: "auto" }}>
                <ImageComponent source={documentUrl} style={{ height: "100px", width: "100px", border: "none" }} />
                <a style={{ fontSize: "15px" }} className='p-2 text-dark' href='' onClick={handleDownload}>
                    View Image
                </a>
            </Card>
            {/* } */}

        </>
    );
};

export default DocumentDownload;