import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./BannerConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';

  constructor() {
    makeAutoObservable(this);
  }

  // fetch = async (filter) => {
  //   const response = await doPOST(
  //     ENDPOINTS.grid(this.page, this.rows, filter));
  //   if (response.status === 200) {
  //     this.records = response.data.rows;
  //     this.totalRecords = response.data.records;
  //   }
  //   return response?.data
  // };

  fetch = async function (filter) {

    // if (filter !== this.filterOption) {
    //   this.page = 1
    //   this.rows = 20
    // }
    this.filterOption = filter

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filter));
    if (response.status === 200) {
      this.records = response.data.data.rows;
      this.totalRecords = response.data.data.records
    }
    return response?.data

  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };


  edit = async function (data, id) {
    const response = await doPOST(ENDPOINTS?.edit(id), data);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
}

const BannerService = new Service();
export default BannerService;
