import React, { useEffect, useState } from "react";
import { Row, Col, Label } from 'reactstrap'
import CrossButton from "../Buttons/CrossButton";
import ImageComponent from "./ImageComponent";
import SaveButton from "../Buttons/SaveButton";


const ImageModal = (props) => {

    const { handleSubmit, imageUrl, setClose, style } = props

    const [imageDataUrl, setImageDataUrl] = useState(null);

    useEffect(() => {
        if (imageUrl) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imgDataUrl = e.target.result;

                setImageDataUrl({
                    imageUrl,
                    imgDataUrl,
                });
            };
            reader.readAsDataURL(imageUrl)
        }

    }, [])

    return <div style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,

    }} className="d-flex justify-content-center align-items-center">
        <div style={{ backgroundColor: 'white', width: '450px', height: '256px', borderRadius: '10px', overflowY: 'scroll' }}>

            <div style={{ position: 'relative', right: 5 }}>
                <CrossButton onClick={setClose} />
            </div>

            <div className="d-flex justify-content-center align-items-center row col-md-12 ml-auto mr-auto" style={{
                objectFit: "contain"
            }}>
                <img
                    alt="Profile"
                    style={{ height: '140px', width: '140px', top: '50px', objectFit: 'contain', ...style }}
                    src={URL.createObjectURL(imageUrl)}
                />
            </div>



            <Row className="" style={{ marginTop: "50px" }}>
                <SaveButton
                    style={{ right: "0%", bottom: "10px", right: "5px" }}
                    onClick={handleSubmit}
                    label={"Send"}
                    disabled={imageUrl ? false : true}

                />
            </Row>
        </div>
    </div>
};

export default ImageModal;
