import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button, Card, CardBody, Col, Nav, Navbar, Row, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown
} from 'reactstrap'
import SupportService from './SupportService'
import ClientService from '../Clients/ClientService'
import SupportCard from './SupportCard'
import { DialogContext } from '../../store/context/DialogContext'
import { Pagination } from '../../components'
import { useCookies } from "react-cookie";
import { ThreeBounce } from 'better-react-spinkit'
import { values } from 'lodash'


const Support = () => {
  const [data, setData] = useState([])
  const [ticketNo, setTicketNo] = useState()
  const [activedata, setActiveData] = useState([])
  const [resolveddata, setResolvedData] = useState([])
  const [loading, setLoading] = useState(false);
  const [status, setstatus] = useState("")
  const [issueType, setIssueType] = useState("all")
  const [customerName, setCustomerName] = useState("")
  const [cookies, setCookie] = useCookies(["user"]);
  const [drp_primary1, setDrp_primary1] = useState(false);
  const [rowsPerPage, setRowPerPage] = useState(
    cookies[`page-Support`]
  );

  const { showConfirm, showError } = useContext(DialogContext);
  const navigate = useNavigate()
  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await SupportService.fetch();
      setData(response?.rows)
      const active = await SupportService.fetchActive();
      setActiveData(active?.rows)
      const resolved = await SupportService.fetchResolved();
      setResolvedData(resolved?.rows)
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };




  const getGridData = async () => {
    // if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      if (ticketNo) {
        filter.ticketNo = ticketNo
      }
      if (issueType === 'active') {
        filter.status = 1
      }
      else if (issueType === 'resolved') {
        filter.status = 2
      }
      else if (issueType === '') {
        filter.status = 3
      }

      const response = await SupportService.fetch(filter);
      setData(response?.data?.rows)

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const getSelectedCustomerIssues = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};

      if (customerName != "") {
        filter.search = customerName
      }
      const response2 = await SupportService.fetch(filter);
      setData(response2)
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  useEffect(() => {
    getGridData()
  }, [issueType, ticketNo, SupportService?.rows, SupportService?.page])



  const returnSelectedBorder = (type) => type === issueType ? "3px solid #756464" : "none"
  // useEffect(() => {
  //   const active = data.filter(item => item.status === 1 || item.status === 2);
  //   console.log(active);
  //   setActiveData(active)
  //   const resolved = data.filter(item => item.status === 3);
  //   setResolvedData(resolved)
  // }, [issueType])

  const actionOptions = [
    {
      icon: "",
      label: "All",
      value: "all",
      callback: (e) => {
        setIssueType("all")
      },
    },
    {
      icon: "",
      label: "Active",
      value: "active",
      callback: (e) => {
        setIssueType("active")
      },
    },
    {
      icon: "",
      label: "Resolved",
      value: "resolved",
      callback: (e) => {
        setIssueType("resolved")
      },
    }
  ]
  const changePagination=()=>{
    if(SupportService.page!=1){
      SupportService.page=1;
    }
  }
  return (
    <div>
      <Row>
        <div
          className="d-flex ms-3 my-3 "
        // style={{ position: "relative", left: "20px" }}
        >
          <Row style={{ marginRight: "20px" }}>
            <Col className="ps-0">
              <div className="d-flex">
                <input
                  // style={{ width: "15%" }}
                  className="form-control  "
                  placeholder="Search By ticket No"
                  id="myInput" type="text"
                  value={ticketNo}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && ticketNo) {
                      getGridData()
                    }
                    else if (e.key === 'Enter' && !ticketNo) {
                      getGridData()
                    }
                  }}
                  onChange={(e) => {
                    setTicketNo(e.target.value)
                  }} />
              </div>

            </Col>
          </Row>
          <Row style={{ marginRight: "20px" }}>
            <Col className="ps-0">
              <div className="d-flex">
                <input
                  // style={{ width: "15%" }}
                  className="form-control  "
                  placeholder="Customer Name"
                  id="myInput"
                  type="text"

                  value={customerName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && customerName) {
                      getSelectedCustomerIssues()
                    }
                    else if (e.key === 'Enter' && !customerName) {
                      getSelectedCustomerIssues()
                    }
                  }}
                  onChange={(e) => {
                    setCustomerName(e.target.value)
                  }} />
              </div>
            </Col>
          </Row>
          {/* <Row style={{ marginRight: "20px", }}>
            <Col className="ps-0">
              <Dropdown
                onClick={(e) => e.preventDefault()}
                isOpen={drp_primary1}
                toggle={() => setDrp_primary1(!drp_primary1)}
              >
                <DropdownToggle
                  onClick={(e) => e.preventDefault()}
                  tag="button"
                  className="btn  bg-white"
                  style={{ padding: "0 5px 0 10px", width: "120px", border: "1px solid #ced4da" }}
                >
                  <span style={{ float: "left", lineHeight: "35px" }}>
                    Status
                  </span>
                  <span
                    className="bx bx-chevron-down"
                    style={{ fontSize: "24px", lineHeight: "28px" }}
                  />
                </DropdownToggle>
                <DropdownMenu onClick={(e) => e.preventDefault()}>
                  {actionOptions?.map((singleAction, key) => {
                    return (
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          singleAction.callback(singleAction.value);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {singleAction.label}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row> */}

          <Row style={{ position: "relative" }}>
            <Button
              className="uil-search"
              // style={{
              //   width: "fit-content",
              //   borderRadius: "20px",
              //   backgroundColor: "#D68910",
              //   border: "none"
              // }}
              style={{
                borderRadius: "20px",
                backgroundColor: "#D68910",
                border: "none",
                fontSize: "20px",
                padding: "2px 9px"
              }}
              onClick={()=>{
                changePagination();
                getGridData()
                }} 
                >
            </Button>
          </Row>


        </div>


        {/* <div className="d-flex">
          <input className="form-control  " value={issueType} onKeyDown={(e) => {
            if (e.key === 'Enter' && issueType) {
              fetchData()
            }
            else if (e.key === 'Enter' && !issueType) {
              if (issueType)
                fetchData()
            }
          }} onChange={(e) => {
            setIssueType(e.target.value)
          }} placeholder="Search By Phone" id="myInput" type="text" />
        </div> */}

      </Row>
      <Card
        className="mt-10 mb-10 px-4"
        style={{
          height: `calc(100vh - 128px)`,
          overflow: "scroll",
          paddingBottom: "150px"
        }}>
        <Row>
          <Navbar className='px-3'>
            <Nav className="mr-auto text-dark w-100" style={{ fontSize: "17px", color: "black", borderBottom: "3px solid #0000001A" }}>
              {/* <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("all"), marginBottom: "-3px" }} className="" onClick={() => setIssueType("all")}>View All <span style={{ color: "#756464" }}>({data?.length})</span> </Link>
              <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("active"), marginBottom: "-3px" }} className="px-4 " onClick={() => setIssueType("active")}>Active Issue <span style={{ color: "#756464" }}>({activedata?.length})</span>  </Link>
              <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("resolved"), marginBottom: "-3px" }} className="px-4 " onClick={() => setIssueType("resolved")}>Resolved <span style={{ color: "#756464" }}>({resolveddata?.length})</span>  </Link> */}

              <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("all"), marginBottom: "-3px" }} className="" onClick={() => setIssueType("all")}>View All  </Link>
              <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("active"), marginBottom: "-3px" }} className="px-4 " onClick={() => setIssueType("active")}>Active Issue</Link>
              <Link style={{ fontSize: "20px", fontWeight: 500, color: "black", borderBottom: returnSelectedBorder("resolved"), marginBottom: "-3px" }} className="px-4 " onClick={() => setIssueType("resolved")}>Resolved  </Link>
            </Nav>
          </Navbar>
          <CardBody className='card-container'>
            {
              loading ?
                <div className="d-flex align-items-center justify-content-center">
                  <ThreeBounce size={20} color="#9F73AB" />
                </div> :
                <>
                  {
                    issueType == "all" && SupportService?.records?.map((ele) => <SupportCard ele={ele} />)
                  }
                  {
                    issueType == "active" && SupportService?.records?.map((ele) => <SupportCard ele={ele} />)
                  }
                  {
                    issueType == "resolved" && SupportService?.records?.map((ele) => <SupportCard ele={ele} />)
                  }
                </>
            }
          </CardBody>

          <div className="xxxxx">
            <Pagination
              page={SupportService.page}
              rows={SupportService.rows}
              total={SupportService.totalRecords}
              onChange={async (page, rows) => {
                setLoading(true);
                await SupportService.onPaginationChange(page, rows);
                setCookie(`page-Support`, rows, { path: "/" });
                setRowPerPage(rows);
                setLoading(false);
              }}
            />
          </div>
        </Row>
      </Card>

    </div>
  )
}
export default Support