import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./MyTransactionConstant";
import { doGET } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filter) => {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filter));
    if (response.status === 200 && !filter?.forDownload) {
      this.records = response.data.data.rows;
      this.totalRecords = response.data.data.totalPages;
    }
    if (!filter?.forDownload) {
      return response?.data.data
    } else {
      return response;
    }

  };

  getUser = async (id) => {
    const response = await doGET(
      ENDPOINTS.getUser(id));
    return response?.data
  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    // await this.fetch(this.filterOption);
  };
}

const FinanceService = new Service();
export default FinanceService;
